import { withRouter } from "react-router";
import Loader from "../../../../common/Loader";
import DocumentForm from "./DocumentForm";
import DocumentList from "./DocumentList";
import useDocumentsView from "./useDocumentsView";

const DocumentsView = withRouter((props) => {
  const fetchId = props.match.params.id;
  const {
    documents,
    setDocuments,
    isLoading,
    setNewFile,
    newFile,
    binary,
    setBinary,
    binaryTemp,
    setBinaryTemp,
    saveFiles,
    fileTypes,
    errors,
    deleteDocumentFile,
    uploadInlineFile,
    updateFileAuthorization,
    fetchDocumentFiles,
    notifyClientByEmail,
    isLoadingNotification,
  } = useDocumentsView(fetchId);

  return (
    <div className="position-relative bg-control p-3">
      {isLoading && (
        <div
          className="d-center w-100 h-100"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: "99999",
            background: "rgba(255,255,255,0.5)",
          }}
        >
          <Loader />
        </div>
      )}
      <div className="row mb-2">
        <div className="col-12 d-flex justify-content-end">
          <button className="btn btn-default btn-sm" onClick={() => notifyClientByEmail()}>
            <i className="fa fa-envelope mr-2" />
            {isLoadingNotification ? <Loader /> : "Notifier le client"}
          </button>
        </div>
      </div>
      <DocumentList
        documents={documents}
        setDocuments={setDocuments}
        fileTypes={fileTypes}
        deleteDocumentFile={deleteDocumentFile}
        isLoading={isLoading}
        uploadInlineFile={uploadInlineFile}
        updateFileAuthorization={updateFileAuthorization}
        fetchDocumentFiles={fetchDocumentFiles}
      />

      <div className="row">
        <div className="col-12">
          <div className="custom-card mt-3">
            <DocumentForm
              isLoading={isLoading}
              setNewFile={setNewFile}
              newFile={newFile}
              binary={binary}
              setBinary={setBinary}
              binaryTemp={binaryTemp}
              setBinaryTemp={setBinaryTemp}
              saveFiles={saveFiles}
              fileTypes={fileTypes}
              errors={errors}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default DocumentsView;
