import useStoreState from "../../pages/ProjectDetail/Context/useStoreState";
import Loader from "../Loader";

const ExistingFile = ({
  existingFile,
  deleteFile,
  downloadFile,
  isDownloading,
}) => {
  const { items_actions } = useStoreState();
  const openFile = () => {
    items_actions.openFileViewer(existingFile.id, existingFile.category);
  };

  return (
    <div className="d-flex flex-column  align-items-start">
      <div className="mb-2 ">
        <small>
          <i>
            {existingFile.name}
            {existingFile.fileExt}
          </i>
        </small>
      </div>
      <div className="d-center flex-row">
        {existingFile.type != "OTHER" && (
          <button className="btn  btn-sm " onClick={openFile}>
            <i className="fa fa-eye fa-2x" />
          </button>
        )}
        <button className="btn  btn-sm" onClick={downloadFile}>
          {isDownloading ? (
            <Loader color="white" />
          ) : (
            <i className="fa fa-download fa-2x" />
          )}
        </button>
        <button className="btn  btn-sm " onClick={deleteFile}>
          <i className="fa fa-trash-alt fa-2x" />
        </button>
      </div>
    </div>
  );
};

export default ExistingFile;
