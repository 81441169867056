import { useEffect, useState } from "react";
import { authActions } from "../../../../context/actions/authActions";
import useStore from "../../../../context/useStore";
import menuItems from "../../../../menuItems";

function debounce(fn, ms) {
  let timer;
  return (_) => {
    clearTimeout(timer);
    timer = setTimeout((_) => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

const useTopMenu = (props) => {
  const [state, dispatch] = useStore();
  console.log("state global", state);
  const actions = authActions(state, dispatch);
  const items = menuItems;
  const [subMenu, setSubMenu] = useState([]);
  const [activeLink, setActiveLink] = useState(-1);
  const [subActiveLink, setSubActiveLink] = useState(-1);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setSubActiveLink(-1);
  }, [activeLink]);

  useEffect(() => {
    items.forEach((item, itk) => {
      if (item.items) {
        if (item.items.find((it) => it.path == props.location.pathname)) {
          setActiveLink(itk);
          setSubMenu(item.items);
          var subIndex = item.items.findIndex(
            (it) => it.path == props.location.pathname
          );
          setTimeout(() => {
            setSubActiveLink(subIndex);
          }, 500);
        }
      }
      if (item.path == props.location.pathname) {
        setActiveLink(itk);
      }
    });
  }, [props.location.pathname]);

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    }
  }, []);

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setIsMobile(window.innerWidth <= 768);
    }, 500);

    window.addEventListener("resize", debouncedHandleResize);
    // window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
      // window.removeEventListener("scroll", handleScroll);
    };
  });

  var authData = state.auth;

  return {
    state,
    authData,
    authActions,
    items,
    activeLink,
    subActiveLink,
    setActiveLink,
    setSubActiveLink,
    actions,
    setSubMenu,
    subMenu,
    isMobile,
  };
};

export default useTopMenu;
