import { Switch } from "react-router";
import FilePreview from "../../components/common/FileViewer/FilePreview";
import FileViewer from "../../components/common/FileViewer/FileViewer";
import PrivateRoute from "../../components/common/PrivateRoute";
import Dashboard from "../../components/pages/Dashboard/Dashboard";
import Profile from "../../components/pages/Profile/Profile";
import ProjectDetail from "../../components/pages/ProjectDetail/ProjectDetail";
import UserManagersList from "../../components/pages/UserManagers/UserManagerList/UserManagersList";
import UserManagersDetail from "../../components/pages/UserManagers/UserManagersDetail/UserManagersDetail";

const privateRoutes = () => {
  return (
    <Switch>
      <PrivateRoute path="/" exact component={Dashboard} />
      <PrivateRoute path="/dashboard" exact component={Dashboard} />
      <PrivateRoute path="/profile" exact component={Profile} />
      <PrivateRoute path="/user-managers" exact component={UserManagersList} />
      <PrivateRoute
        path="/user-managers/:id"
        exact
        component={UserManagersDetail}
      />
      <PrivateRoute path="/projects/:id" exact component={ProjectDetail} />
      <PrivateRoute path="/fileviewer" exact component={FileViewer} />
    </Switch>
  );
};

export default privateRoutes;
