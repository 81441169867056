import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import useStore from "../../../context/useStore";
import Control from "../Controls/Control";

const Conversation = ({
  comments,
  updateCommentStatus,
  fileId,
  isOpen,
  isSavingComment,
}) => {
  const [state] = useStore();
  const { COMMENT_STATUSES } = state.constants.items;

  const [statusEdit, setStatusEdit] = useState(null);

  var commentsColors = [];
  var colors = [
    "primary",
    "info",
    "success",
    "danger",
    "warning",
    "tertiary",
    "dark",
  ];
  var incr = 0;
  comments.forEach((comment) => {
    if (!commentsColors.find((c) => c.commentedBy == comment.commentedBy.id)) {
      commentsColors.push({
        commentedBy: comment.commentedBy.id,
        color: colors[incr],
      });
      incr++;
    }
  });

  useEffect(() => {
    setTimeout(() => {
      scrollBottom();
    }, 500);
  }, []);

  useEffect(() => {
    scrollBottom();
    setStatusEdit(null);
  }, [comments, isOpen]);

  const scrollBottom = () => {
    var objDiv = document.getElementById("conversation");
    if (objDiv) {
      objDiv.scrollTop = objDiv.scrollHeight;
    }
  };

  return (
    <div
      style={{
        maxHeight: "40vh",
        overflowY: "scroll",
        overflowX: "hidden",
      }}
      id="conversation"
    >
      {comments.map((comment) => {
        var color = commentsColors.find(
          (c) => c.commentedBy == comment.commentedBy.id
        ).color;
        var status = COMMENT_STATUSES.find((st) => st.id == comment.status);
        return (
          <div className={`bg-${color} rounded text-white p-2 my-2`}>
            <p>
              <span className="mr-2">{comment.commentedBy.fullName} :</span>
              <span className="font-weight-bold">{comment.content}</span>
            </p>
            <div className="d-flex justify-content-between mt-2 align-items-end">
              <small className="mr-2">
                {comment.commentedByRef != "UserManager" &&
                  statusEdit != comment._id && (
                    <div
                      className="cursor-pointer"
                      onClick={() => setStatusEdit(comment._id)}
                    >
                      <i className={`fa fa-${status.icon} mr-2`} />
                      {status.name}
                    </div>
                  )}
                {statusEdit == comment._id && (
                  <Control
                    type="btnList"
                    btnInline
                    name="status"
                    dataIndex="id"
                    dataLabel="name"
                    disabled={isSavingComment}
                    datas={COMMENT_STATUSES}
                    value={comment.status}
                    change={(e) =>
                      updateCommentStatus(fileId, comment._id, e.target.value)
                    }
                  />
                )}
              </small>

              <small>
                {comment.commentedBy.fullName}{" "}
                <small className="d-block text-right" style={{ fontSize: 9 }}>
                  {DateTime.fromISO(comment.commentedAt).toFormat(
                    "dd/MM HH'h'mm"
                  )}
                </small>
              </small>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Conversation;
