import useStoreState from "../../pages/ProjectDetail/Context/useStoreState";

const VideoItem = ({ file, deleteFile }) => {
  const { items_actions } = useStoreState();
  const openFile = () => {
    items_actions.openFileViewer(file.id, file.category);
  };

  return (
    <div className="d-center flex-row justify-content-start">
      <a
        href={`https://www.youtube.com/watch?v=${file.youtubeId}`}
        target="_BLANK"
        className="btn  btn-sm"
      >
        <i className="fa fa-youtube fa-2x" />
      </a>
      <button className="btn  btn-sm " onClick={openFile}>
        <i className="fa fa-eye fa-2x" />
      </button>
      <button className="btn  btn-sm" onClick={() => deleteFile(file.id)}>
        <i className="fa fa-trash-alt fa-2x" />
      </button>
    </div>
  );
};

export default VideoItem;
