import { Link } from "react-router-dom";
import SaveBtn from "../../../common/SaveBtn/SaveBtn";
import Table from "../../../common/Table/Table";

const ProjectList = ({ props }) => {
  const {
    search,
    setSearch,
    getProjects,
    projects,
    isLoading,
    setIsLoading,
    errors,
    removeProject,
    isRemoving,
  } = props;
  return (
    <div>
      <Table
        fields={[
          {
            name: "Nom",
            path: "*",
            render: (el) => (
              <>
                <Link
                  to={`projects/${el.id}`}
                  className="btn btn-link w-100 d-flex justify-content-start"
                >
                  {el.name}
                </Link>
              </>
            ),
          },
          {
            name: "Description",
            path: "*",
            render: (el) => (
              <>
                <Link
                  to={`projects/${el.id}`}
                  className="btn btn-link w-100 d-flex justify-content-start"
                >
                  {el.description}
                </Link>
              </>
            ),
          },
          {
            name: "Supprimer",
            path: "*",
            render: (el) => (
              <>
                <SaveBtn
                  save={() => removeProject(el.id)}
                  isSaving={isRemoving == el.id}
                  type="danger"
                  text="supprimer"
                />
              </>
            ),
          },
        ]}
        datas={projects}
      />
    </div>
  );
};

export default ProjectList;
