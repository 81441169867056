import React from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import TopMenu from "./components/common/layout/TopMenu/TopMenu";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AnimatePresence } from "framer-motion";
import Loader from "./components/common/Loader";
import RightPanel from "./components/overlays/RightPanel/RightPanel";
import LeftPanel from "./components/overlays/LeftPanel/LeftPanel";
import PublicRoutes from "./routes/PublicRoutes/PublicRoutes";
import PrivateRoutes from "./routes/PrivateRoutes/PrivateRoutes";
import useAuth from "./hooks/userAuth/useAuth";
import Modal from "./components/overlays/Modal/Modal";
import RoleStyle from "./components/common/layout/RoleStyle";

const App = () => {
  const { constantsLoaded } = useAuth();
  return constantsLoaded ? (
    <>
      <Router>
        <div className="wrapper">
          <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
          />

          <TopMenu />

          <div id="content" style={{ padding: "0 30px" }}>
            <PublicRoutes />
            <PrivateRoutes />
          </div>
        </div>
        <Modal />
        <RightPanel />
        <LeftPanel />
      </Router>
      <RoleStyle />
    </>
  ) : (
    <div className="d-center flex-column" style={{ minHeight: "100vh" }}>
      <img src="/images/logo.svg" style={{ width: 200 }} />
      <Loader className="mt-3" />
    </div>
  );
};

export default App;
