import React from "react";
import Control from "../../../common/Controls/Control";
import SaveBtn from "../../../common/SaveBtn/SaveBtn";

const UserManagersFilters = ({
  search,
  setSearch,
  searchUserManagers,
  isLoading,
}) => {
  return (
    <div className="col-12 px-0 mb-3">
      <div className="custom-card">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            searchUserManagers();
          }}
        >
          <Control
            label="Rechercher"
            name="searchString"
            type="text"
            placeholder="Nom, prénom, email..."
            value={search.searchString}
            change={(e) =>
              setSearch({
                ...search,
                searchString: e.target.value,
              })
            }
          />
          <div className="d-flex justify-content-end mt-3 w-100">
            <SaveBtn
              save={searchUserManagers}
              isSaving={isLoading}
              text="Rechercher"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserManagersFilters;
