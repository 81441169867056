import React, { useEffect } from "react";
import FilenameSup from "./FilenameSup";

const Nav = ({
  goToNextSlide,
  goToPrevSlide,
  goToSlideIndex,
  currentslide,
  files,
  setCurrentSlide,
  isGettingMedia,
}) => {
  const pressedKey = (e) => {
    if (e.keyCode == 37) {
      goToPrevSlide();
    }
    if (e.keyCode == 39) {
      goToNextSlide();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", pressedKey);
    return () => document.removeEventListener("keydown", pressedKey);
  }, [currentslide]);

  return (
    <>
      <div
        className="position-absolute "
        style={{
          top: "50vh",
          left: 0,
        }}
      >
        <button
          onClick={goToPrevSlide}
          disabled={isGettingMedia}
          className="btn d-center h-100  bg-trans border-0 btn-default  btn-sm"
        >
          <i className="fa fa-chevron-left fa-3x" />
        </button>
      </div>
      <div
        className="position-absolute "
        style={{
          top: "50vh",
          right: 0,
        }}
      >
        <button
          disabled={isGettingMedia}
          onClick={goToNextSlide}
          className="btn d-center h-100 bg-trans border-0  btn-default  btn-sm"
        >
          <i className="fa fa-chevron-right fa-3x" />
        </button>
      </div>
      <div
        className="navig position-fixed w-100"
        style={{
          bottom: 0,
          left: 0,
          zIndex: "9999",
        }}
      >
        <div className="d-center flex-row flex-wrap">
          {files.map((file, filek) => (
            <button
              className={
                "btn d-center flex-fill   btn-sm " +
                (filek === currentslide ? "btn-primary" : "btn-default")
              }
              style={{ transition: "400ms" }}
              disabled={isGettingMedia}
              onClick={() => goToSlideIndex(filek)}
            >
              {file.name}
              <FilenameSup isTemp={file.isWatermarkVersion} />
            </button>
          ))}
        </div>
      </div>
    </>
  );
};

export default Nav;
