import { toast } from "react-toastify";
import dataService from "../../../../../helpers/dataService";
import FilePreview from "../../../../common/FileViewer/FilePreview";
import { getInitConfig } from "../initConfig";

export const itemsActions = (state, dispatch, mActions, gState) => {
  const initItems = async (fetchId) => {
    const initConfig = getInitConfig(fetchId, gState.auth.user.role);
    dispatch({ type: "IS_LOADING", payload: true });
    var allPromises = [];
    var actionUrls = {};
    var items = {};
    initConfig.forEach((url) => {
      actionUrls[url.name] = { ...url.paths };
      if (url.paths?.get && url.fetchOnLoad) {
        allPromises.push(
          new Promise((resolve, reject) => {
            dataService.get(url.paths.get, resolve, reject);
          })
        );
      }
      if (!url.fetchOnLoad) {
        allPromises.push(
          new Promise((resolve, reject) => {
            if (Array.isArray(url.initialState)) {
              resolve([...url.initialState]);
            } else {
              resolve({ ...url.initialState });
            }
          })
        );
        // items[url.name] = url.initialState;
        // console.log("it", items);
      }
    });
    if (allPromises.length) {
      Promise.all(allPromises)
        .then((values) => {
          values.forEach((value, key) => {
            items[initConfig[key].name] = value;
          });

          dispatch({
            type: "INIT_ITEMS",
            payload: { items, actionUrls },
          });
        })
        .catch((err) => {
          console.log("error", err);
          Object.values(err).forEach((er) => toast.error(er));
        });
    } else {
      var defaultItems = {};
      initConfig.forEach((url) => {
        defaultItems[url.name] = url.initialState;
      });

      dispatch({
        type: "INIT_ITEMS",
        payload: { items: defaultItems, actionUrls },
      });
    }
  };

  const updateItemObject = (itemName, e) => {
    const { name, value, type, checked, k } = e.target;
    var newItem = { ...state.items[itemName] };

    if (type == "checkbox") {
      newItem[name] = checked ? true : false;
    } else {
      newItem[name] = value;
    }

    dispatch({
      type: "UPDATE_ITEM",
      payload: {
        item: itemName,
        value: newItem,
      },
    });
  };

  const setIsLoading = (type = null, isLoading) => {
    dispatch({
      type: `IS_LOADING${type ? "_" + type : ""}`,
      payload: isLoading,
    });
  };

  const fetchClients = () => {
    dataService.get(`user-customers`, updateClients);
  };

  const updateClients = (clients) => {
    dispatch({
      type: "UPDATE_CLIENTS",
      payload: clients,
    });
  };

  const setCurrentPanel = (panelId) => {
    dispatch({
      type: "UPDATE_CURRENT_PANEL",
      payload: panelId,
    });
  };

  const setErrors = (errors) => {
    dispatch({
      type: "UPDATE_ERRORS",
      payload: errors,
    });
  };

  const updateProject = (project) => {
    dispatch({
      type: "UPDATE_PROJECT",
      payload: project,
    });
  };

  const updateNewClient = (client) => {
    dispatch({
      type: "UPDATE_NEW_CLIENT",
      payload: client,
    });
  };

  const saveNewClient = () => {
    //CREATION POST
    setIsLoading("NEW_CLIENT", true);
    dataService.post(
      `user-customers`,
      state.items.newClient,
      (datas) => {
        toast.success(
          "Le client a bien été créé, vous pouvez le sélectionner dans le projet"
        );
        updateNewClient(datas);
        fetchClients();
      },
      setErrors
    );
  };

  const saveProject = () => {
    //CREATION POST
    if (!state.items.project.id) {
      setIsLoading("PROJECT", true);
      dataService.post(
        `projects`,
        state.items.project,
        (datas) => {
          updateProject(datas);
          toast.success("Le projet a bien été créé");
          window.location.href = `/projects/${datas.id}`;
        },
        setErrors,
        () => setIsLoading("PROJECT", false)
      );
    } else {
      //MODIFICATION PATCH
      setIsLoading("PROJECT", true);
      dataService.patch(
        `projects/${state.items.project.id}`,
        state.items.project,
        (datas) => {
          updateProject(datas);
          toast.success("Le projet a bien été modifié");
        },
        setErrors,
        () => setIsLoading("PROJECT", false)
      );
    }
  };

  const sendActivationMail = () => {};

  const updateNeedsForms = (needsForms) => {
    dispatch({
      type: "UPDATE_NEEDS_FORMS",
      payload: needsForms,
    });
  };
  const saveNeedsForm = (needsForm) => {
    if (needsForm.id) {
      setIsLoading("NEEDS_FORMS", true);
      dataService.patch(
        `needs-forms/${needsForm.id}`,
        needsForm,
        (datas) => {
          toast.success("Le formulaire de besoins a bien été enregistré");
          dispatch({
            type: "UPDATE_NEEDS_FORMS",
            payload: needsForm.map((nf) =>
              nf.type == datas.type ? needsForm : nf
            ),
          });
        },
        setErrors,
        () => setIsLoading("NEEDS_FORMS", true)
      );
    } else {
      setIsLoading("NEEDS_FORMS", true);
      dataService.post(
        `needs-forms`,
        { ...needsForm, project: state.items.project.id },
        (datas) => {
          toast.success("Le formulaire de besoins a bien été enregistré");

          dispatch({
            type: "UPDATE_NEEDS_FORMS",
            payload: state.items.needsForms.map((nf) =>
              nf.type == datas.type ? datas : nf
            ),
          });
        },
        setErrors,
        () => setIsLoading("NEEDS_FORMS", true)
      );
    }
  };

  const updateDocuments = (documents) => {
    dispatch({
      type: "UPDATE_DOCUMENTS",
      payload: documents,
    });
  };
  const updateAnnexes = (annexes) => {
    dispatch({
      type: "UPDATE_ANNEXES",
      payload: annexes,
    });
  };
  const updateDevis = (devis) => {
    dispatch({
      type: "UPDATE_DEVIS",
      payload: devis,
    });
  };

  const openFileViewer = (currentFileId = null, category) => {
    var categories = {
      ANNEX: "annexes",
      QUOTE: "devis",
      DOCUMENT: "documents",
    };

    mActions.updateModal({
      isOpen: true,
      content: (
        <FilePreview
          currentFileId={currentFileId}
          filesArray={state.items[categories[category]]}
        />
      ),
    });
  };

  return {
    initItems,
    updateItemObject,
    setCurrentPanel,
    updateProject,
    saveProject,
    updateNewClient,
    saveNewClient,
    updateClients,
    fetchClients,
    sendActivationMail,
    updateNeedsForms,
    saveNeedsForm,
    updateDocuments,
    openFileViewer,
    updateAnnexes,
    updateDevis,
  };
};
