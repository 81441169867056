import { useState } from "react";
import { toast } from "react-toastify";
import CommentsPanel from "../../components/pages/ProjectDetail/CommentsPanel/CommentsPanel";
import { rightPanelActions } from "../../context/actions/rightPanelActions";
import useStore from "../../context/useStore";
import dataService from "../../helpers/dataService";

const useComments = (refetch) => {
  const [state, dispatch] = useStore();
  const rpActions = rightPanelActions(state, dispatch);

  const openComments = (file) => {
    rpActions.updateRightPanel({
      isOpen: true,
      content: <CommentsPanel myFile={file} refetch={refetch} />,
    });
  };

  return {
    openComments,
  };
};

export default useComments;
