import { modalActions } from "../../../context/actions/modalActions";
import useStore from "../../../context/useStore";
import FilePreview from "./FilePreview";

const FileViewer = () => {
  const [state, dispatch] = useStore();
  const m_actions = modalActions(state, dispatch);

  const openModal = () => {
    m_actions.updateModal({
      isOpen: true,
      content: <FilePreview />,
    });
  };

  return (
    <button className="btn btn-primary" onClick={openModal}>
      Ouvrir
    </button>
  );
};

export default FileViewer;
