import React from "react";
import useStoreState from "../Context/useStoreState";
import AnnexeView from "../Views/AnnexeView/AnnexeView";
import DevisView from "../Views/DevisView/DevisView";
import DocumentsView from "../Views/DocumentsView/DocumentsView";
import FormulairesView from "../Views/FormulairesView/FormulairesView";
import InformationsView from "../Views/InformationsView/InformationsView";
import PaiementsView from "../Views/PaiementsView/PaiementsView";
import PanelItem from "./PanelItem";

const ViewSwitcher = () => {
  const { items, items_actions } = useStoreState();
  const { currentPanel } = items.utils;
  const { project } = items;

  const fallBack = <div>Veuillez d'abord enregistrer le projet</div>;

  // switch (currentPanel) {
  //   case "informations":
  //     return <InformationsView />;
  //   case "formulaires":
  //     return project.id ? <FormulairesView /> : fallBack;
  //   case "devis":
  //     return project.id ? <DevisView /> : fallBack;
  //   case "documents":
  //     return project.id ? <DocumentsView /> : fallBack;
  //   case "paiements":
  //     return project.id ? <PaiementsView /> : fallBack;
  // }

  return (
    <>
      <PanelItem name="informations" currentPanel={currentPanel}>
        <InformationsView />
      </PanelItem>

      <PanelItem name="formulaires" currentPanel={currentPanel}>
        {project.id ? <FormulairesView /> : fallBack}
      </PanelItem>
      <PanelItem name="devis" currentPanel={currentPanel}>
        {project.id ? <DevisView /> : fallBack}
      </PanelItem>
      <PanelItem name="documents" currentPanel={currentPanel}>
        {project.id ? <DocumentsView /> : fallBack}
      </PanelItem>
      <PanelItem name="paiements" currentPanel={currentPanel}>
        {project.id ? <PaiementsView /> : fallBack}
      </PanelItem>
      <PanelItem name="annexes" currentPanel={currentPanel}>
        {project.id ? <AnnexeView /> : fallBack}
      </PanelItem>
    </>
  );
};

export default ViewSwitcher;
