import { Route, Switch } from "react-router";
import ActivateAccount from "../../components/pages/ActivateAccount/ActivateAccount";
import Logout from "../../components/pages/Logout/Logout";
import RenewPassword from "../../components/pages/RenewPassword/RenewPassword";
import Test from "../../components/pages/Test";

const publicRoutes = () => {
  return (
    <Switch>
      <Route path="/renew-password/:token" exact component={RenewPassword} />
      {process.env.NODE_ENV == "development" && (
        <Route path="/test" exact component={Test} />
      )}
      <Route
        path="/activate-account/:token"
        exact
        component={ActivateAccount}
      />
      <Route path="/renew-password/:token" exact component={RenewPassword} />
      <Route path="/logout" exact component={Logout} />
    </Switch>
  );
};

export default publicRoutes;
