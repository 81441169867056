export const getInitConfig = (fetchId, role) => {
  return [
    {
      name: "project",
      fetchOnLoad: fetchId == "create" ? false : true,
      paths: {
        page: "projects",
        get: fetchId !== "create" ? `projects/${fetchId}` : null,
        post: "projects",
        patch: `projects/${fetchId}`,
        remove: `projects/${fetchId}`,
      },

      initialState: {
        name: "",
        description: "",
        client: "",
      },
    },
    {
      name: "clients",
      fetchOnLoad: true,
      paths: {
        get: "user-customers",
      },
      initialState: [],
    },
    {
      name: "newClient",
      fetchOnLoad: false,
      initialState: {
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        password: "",
        passwordConfirm: "",
      },
    },
    {
      name: "documents",
      fetchOnLoad: false,

      initialState: [],
    },
    {
      name: "annexes",
      fetchOnLoad: false,

      initialState: [],
    },
    {
      name: "devis",
      fetchOnLoad: false,

      initialState: [],
    },
    {
      name: "utils",
      fetchOnLoad: false,
      initialState: {
        panels: [
          {
            id: "informations",
            name: "Informations",
          },
          {
            id: "formulaires",
            name: "Besoins",
          },
          {
            id: "devis",
            name: "Devis",
          },
          {
            id: "documents",
            name: "Documents",
          },
          {
            id: "paiements",
            name: "Paiements",
          },
          {
            id: "annexes",
            name: "Annexes",
          },
        ],
        currentPanel: "informations",
        projectId: fetchId,
      },
    },

    { name: "errors", fetchOnLoad: false, initialState: {} },

    {
      name: "templateForms",
      fetchOnLoad: true,
      paths: {
        get: "template-forms",
      },
      initialState: [],
    },
    {
      name: "needsForms",
      fetchOnLoad: fetchId == "create" ? false : true,
      paths: {
        get: fetchId !== "create" ? `projects/${fetchId}/needs-forms` : null,
      },
      initialState: [],
    },
  ];
};
