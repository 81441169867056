import Control from "../../../../common/Controls/Control";
import SaveBtn from "../../../../common/SaveBtn/SaveBtn";
import useStoreState from "../../Context/useStoreState";

const ProjectForm = ({ showForm, setShowForm }) => {
  const { state, items, items_actions } = useStoreState();
  const { project, errors, clients } = items;

  const handleChange = (e) => {
    items_actions.updateProject({
      ...project,
      [e.target.name]: e.target.value,
    });
  };

  const handleSave = () => {
    items_actions.saveProject();
  };

  return (
    <div className="custom-card vivify pullRight">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSave();
        }}
      >
        <Control
          label="Nom du projet"
          type="text"
          name="name"
          value={project.name}
          change={handleChange}
          error={errors}
        />
        <Control
          label="Description"
          type="text"
          name="description"
          value={project.description}
          change={handleChange}
          error={errors}
        />
        <Control
          label="Client"
          type="select"
          name="client"
          datas={clients}
          value={project.client}
          dataIndex="id"
          dataLabel="firstname"
          dataLabel2="lastname"
          dataLabel3="email"
          change={handleChange}
          error={errors}
        />
        <button
          type="button"
          className="btn btn-default btn-sm"
          onClick={() => setShowForm(!showForm)}
        >
          {showForm
            ? "Annuler la création du client"
            : "Créer un nouveau client"}
        </button>
        <div className="d-flex justify-content-end">
          <SaveBtn
            save={items_actions.saveProject}
            typeBtn="submit"
            isSaving={state.isLoadingProject}
            text="Enregistrer le projet"
          />
        </div>
      </form>
    </div>
  );
};

export default ProjectForm;
