import { withRouter } from "react-router";
import FileUpload from "../../../../common/FileUpload/FileUpload";
import SaveBtn from "../../../../common/SaveBtn/SaveBtn";
import AnnexeForm from "./AnnexeForm";
import AnnexeList from "./AnnexeList";
import useAnnexe from "./useAnnexe";

const AnnexeView = withRouter((props) => {
  const fetchId = props.match.params.id;

  const {
    fetchAnnexeFiles,
    existingFiles,
    setExistingFiles,
    saveAnnexeFile,
    deleteAnnexeFile,
    binary,
    setBinary,
    isLoading,
    newFile,
    setNewFile,
    saveNewAnnexeFile,
  } = useAnnexe(fetchId);

  return (
    <div className="row p-3 bg-control">
      <div className="col-12">
        <AnnexeList
          existingFiles={existingFiles}
          setExistingFiles={setExistingFiles}
          deleteAnnexeFile={deleteAnnexeFile}
        />
        <div className="custom-card mt-3">
          <AnnexeForm
            newFile={newFile}
            setNewFile={setNewFile}
            isLoading={isLoading}
            saveNewAnnexeFile={saveNewAnnexeFile}
          />
        </div>
      </div>
    </div>
  );
});

export default AnnexeView;
