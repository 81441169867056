import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import dataService from "../../../../../helpers/dataService";
import useFile from "../../../../../hooks/useFile/useFile";
import useStoreState from "../../Context/useStoreState";

var nbFilesToUpload = 0;

const useDocumentsView = (fetchId) => {
  const { uploadFile, deleteFile } = useFile();
  const { gState, items_actions } = useStoreState();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingNotification, setIsLoadingNotification] = useState(false);
  const [documents, setDocuments] = useState([]);
  const { FILE_TYPES } = gState.constants.items;
  const [errors, setErrors] = useState({});
  const newFileInitialState = {
    name: "",
    type: "",
    binary: null,
    binaryAuthorizedToView: false,
    binaryTemp: null,
    binaryTempAuthorizedToView: false,
  };

  const [newFile, setNewFile] = useState(newFileInitialState);

  useEffect(() => {
    fetchDocumentFiles();
  }, []);

  useEffect(() => {
    if (documents.length) {
      items_actions.updateDocuments(documents);
    }
  }, [documents]);

  const fetchDocumentFiles = () => {
    setIsLoading(true);
    dataService.get(
      `projects/${fetchId}/files/document`,
      (datas) => {
        var nameArray = [];
        var formattedDatas = [];
        datas.forEach((data) => {
          if (!nameArray.includes(data.name)) {
            nameArray.push(data.name);
          }
        });
        for (var key in nameArray) {
          var name = nameArray[key];
          var prodFile = datas.find((da) => da.name == name && !da.isWatermarkVersion);
          var waterFile = datas.find((da) => da.name == name && da.isWatermarkVersion);
          formattedDatas.push({
            file: prodFile,
            fileTemp: waterFile,
          });
        }
        formattedDatas = formattedDatas.map((fd) => {
          if (!fd.file) {
            return {
              fileTemp: {
                ...fd.fileTemp,
                type: fd.fileTemp.fileType,
                name: fd.fileTemp.name,
                binaryTempAuthorizedToView: fd.fileTemp.authorizedToView,
              },
              file: {
                binaryAuthorizedToView: false,
                type: fd.fileTemp.fileType,
                name: fd.fileTemp.name,
              },
            };
          }
          if (!fd.fileTemp) {
            return {
              fileTemp: {
                type: fd.file.fileType,
                name: fd.file.name,
                binaryTempAuthorizedToView: false,
              },
              file: {
                ...fd.file,
                binaryAuthorizedToView: fd.file.authorizedToView,
                type: fd.file.fileType,
                name: fd.file.name,
              },
            };
          }
          return {
            fileTemp: {
              ...fd.fileTemp,
              type: fd.fileTemp.fileType,
              name: fd.fileTemp.name,
              binaryTempAuthorizedToView: fd.fileTemp.authorizedToView,
            },
            file: {
              ...fd.file,
              type: fd.file.fileType,
              name: fd.file.name,
              binaryAuthorizedToView: fd.file.authorizedToView,
            },
          };
        });
        setDocuments(formattedDatas);
      },
      setErrors,
      () => setIsLoading(false)
    );
  };

  const saveFiles = () => {
    setIsLoading(true);
    if (newFile.type == "VIDEO") {
      uploadFileItem(false);
    } else {
      if (newFile.binary || newFile.binaryTemp) {
        if (newFile.binary) {
          nbFilesToUpload++;
          uploadFileItem(false);
        }
        if (newFile.binaryTemp) {
          uploadFileItem(true);
          nbFilesToUpload++;
        }
      } else {
        toast.error("Veuillez ajouter au moins 1 fichier");
        setIsLoading(false);
      }
    }
  };

  const onFileUploaded = () => {
    nbFilesToUpload--;
    if (nbFilesToUpload == 0) {
      fetchDocumentFiles();
      setNewFile(newFileInitialState);
    }
  };

  const uploadFileItem = (isWatermarkVersion) => {
    setIsLoading(true);
    uploadFile({
      category: "document",
      binary: isWatermarkVersion ? newFile.binaryTemp : newFile.binary,
      type: newFile.type,
      entity: fetchId,
      name: newFile.name,
      isWatermarkVersion: isWatermarkVersion,
      authorizedToView: isWatermarkVersion ? newFile.binaryTempAuthorizedToView : newFile.binaryAuthorizedToView,
      onUploaded: (datas) => {
        if (newFile.type == "VIDEO") {
          fetchDocumentFiles();
          setIsLoading(false);
        }
        toast.success("Fichier envoyé avec succès");
      },
      onError: (err) => {
        setIsLoading(false);
        setErrors(err);
      },
      onFinished: () => {
        onFileUploaded();
      },
    });
  };
  const uploadInlineFile = (file, isWatermarkVersion) => {
    setIsLoading(true);

    uploadFile({
      category: "document",
      binary: file.binary,
      type: file.type,
      entity: fetchId,
      name: file.name,
      isWatermarkVersion: isWatermarkVersion,
      authorizedToView: false,
      onUploaded: (datas) => {
        fetchDocumentFiles();
        toast.success("Fichier envoyé avec succès");
      },
      onError: (err) => {
        setErrors(err);
        setIsLoading(false);
      },
      onFinished: () => {},
    });
  };

  const deleteDocumentFile = (id) => {
    setIsLoading(true);
    setTimeout(() => setIsLoading(false), 3000);
    deleteFile({
      id,
      onRemoved: (datas) => fetchDocumentFiles(),
      onError: (err) => {
        setErrors(err);
        setIsLoading(false);
      },
      onFinished: () => {},
    });
  };

  const updateFileAuthorization = (isAuthorized, file) => {
    setIsLoading(true);
    dataService.patch(
      `files/${file.id}`,
      {
        fileType: file.type,
        authorizedToView: isAuthorized,
      },
      (datas) => {
        fetchDocumentFiles();
      },
      (err) => {
        setIsLoading(false);
        setErrors(err);
      },
      () => {}
    );
  };

  const notifyClientByEmail = () => {
    if (
      window.confirm(
        "Confirmez-vous l'envoi d'un email au client pour le notifier qu'il y a eu des modifications sur les documents ?"
      )
    ) {
      setIsLoadingNotification(true);
      dataService.post(
        `projects/${fetchId}/files/notify-upload`,
        {},
        (datas) => {
          toast.success("La notification a bien été envoyé par email.");
        },
        (err) => {},
        () => {
          setIsLoadingNotification(false);
        }
      );
      //appeler l'api pour envoyer l'email de notif...
    }
  };

  return {
    documents,
    setDocuments,
    fetchDocumentFiles,
    isLoading,
    setIsLoading,
    setNewFile,
    newFile,
    saveFiles,
    fileTypes: FILE_TYPES,
    errors,
    deleteDocumentFile,
    uploadInlineFile,
    updateFileAuthorization,
    notifyClientByEmail,
    isLoadingNotification,
  };
};

export default useDocumentsView;
