import { withRouter } from "react-router";
import Control from "../../../../common/Controls/Control";
import FileUpload from "../../../../common/FileUpload/FileUpload";
import SaveBtn from "../../../../common/SaveBtn/SaveBtn";
import useDevis from "./useDevis";

const DevisView = withRouter((props) => {
  const fetchId = props.match.params.id;
  const {
    isLoading,
    setIsLoading,
    quotation,
    setQuotation,
    binary,
    setBinary,
    saveDevis,
    saveDevisFile,
    existingFile,
    deleteDevisFile,
  } = useDevis(fetchId);

  return (
    <div className="row">
      <div className="col-12">
        <div className="custom-card">
          <div className="row">
            <div className="col-12 col-md-6">
              <Control
                label="Lien du devis à signer (sans le https://)"
                type="text"
                name="url"
                value={quotation.url}
                change={(e) =>
                  setQuotation({ ...quotation, url: e.target.value })
                }
              />
              <div className="d-flex w-100 justify-content-end">
                <SaveBtn
                  isSaving={isLoading}
                  save={saveDevis}
                  text="Enregistrer le lien"
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div>
                <label>
                  <span className="label-text">Devis signé</span>
                </label>
              </div>

              <FileUpload
                existingFile={existingFile}
                newBinary={binary}
                clearNewFile={() => setBinary(null)}
                onFileDrop={(file) => setBinary(file)}
                deleteFile={deleteDevisFile}
              />
            </div>
            <div className="col-12">
              <div className="d-flex w-100 justify-content-end">
                <SaveBtn
                  isSaving={isLoading}
                  save={saveDevisFile}
                  text="Envoyer le fichier"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default DevisView;
