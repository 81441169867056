import React, { useState } from "react";
import Control from "../Controls/Control";
import SaveBtn from "../SaveBtn/SaveBtn";
import Conversation from "./Conversation";

const Comment = ({
  handleChange,
  currentFile,
  isSavingComment,
  sendComment,
  updateCommentStatus,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return !["QUOTE", "ANNEX"].includes(currentFile.category) ? (
    <>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="d-center rounded-circle animated fadeIn border-0"
        style={{
          background: "rgba(255,255,255,0.9)",
          position: "absolute",
          top: "85vh",
          right: "calc(2% + 64px)",
          width: 50,
          height: 50,
          textDecoration: "none",
        }}
      >
        <i
          className={`fa ${isOpen ? "fa-times" : "fa-comment"}`}
          style={{
            fontSize: 22,
          }}
        />
        <div
          className="d-center"
          style={{
            position: "absolute",
            top: -6,
            right: -6,
            background: currentFile?.comments?.length > 0 ? "#ff00a2" : "black",
            color: "white",
            borderRadius: "50%",
            width: "24px",
            height: "24px",
            fontSize: "10px",
          }}
        >
          {currentFile?.comments?.length}
        </div>
      </button>
      <div
        className={`position-absolute bg-white rounded p-3 shadow-lg animated faster ${
          isOpen ? "fadeInRight" : "d-none"
        }`}
        style={{
          bottom: "16vh",
          right: "8%",
          maxWidth: 340,
        }}
      >
        <Conversation
          comments={currentFile.comments}
          updateCommentStatus={updateCommentStatus}
          fileId={currentFile.id}
          isOpen={isOpen}
          isSavingComment={isSavingComment}
        />
        <Control
          innerLabelStyle={{ color: "white" }}
          label="Laisser un commentaire"
          value={currentFile.comment || ""}
          type="textarea"
          change={handleChange}
          placeholder={"Ecrivez vos remarques"}
          inputGroupClassname="bg-trans pl-0 w-100"
          className="w-100"
          inputStyle={{
            background: "#dedcdc",
            // width: "100%",
            color: "#000000",
            // fontSize: 14,
            // padding: 4,
            height: 105,
            width: 283,
          }}
          innerLabelStyle={
            {
              // color: "#e0e0e0",
              // display: "block",
              // marginBottom: 5,
            }
          }
        />
        <div className="d-flex justify-content-end w-100">
          <SaveBtn
            save={() => sendComment(currentFile)}
            isSaving={isSavingComment}
            text={
              <>
                Envoyer <i className="fa fa-paper-plane ml-2" />
              </>
            }
          />
        </div>
      </div>
    </>
  ) : null;
};

export default Comment;
