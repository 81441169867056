import Control from "../../common/Controls/Control";
import SaveBtn from "../../common/SaveBtn/SaveBtn";

const ProjectFilters = ({ search, setSearch, searchProjects, isLoading }) => {
  return (
    <div className="col-12 col-md-6 px-0 offset-md-6 offset-0">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          searchProjects();
        }}
      >
        <Control
          label="Recherche par nom"
          name="searchString"
          type="text"
          value={search.searchString}
          change={(e) => setSearch({ ...search, searchString: e.target.value })}
          inputClassName="bg-white"
          suffix={
            <SaveBtn
              save={searchProjects}
              isSaving={isLoading}
              text={<i className="fa fa-search" />}
            />
          }
        />
      </form>
    </div>
  );
};

export default ProjectFilters;
