const PanelItem = ({ name, currentPanel, ...props }) => {
  var isActive = name == currentPanel;
  return (
    <div
      style={{
        height: isActive ? "auto" : 0,
        overflow: "hidden",
      }}
    >
      {props.children}
    </div>
  );
};

export default PanelItem;
