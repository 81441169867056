import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import dataService from "../../../../helpers/dataService";

var searchTimeout = null;
var isFirstLoad = true;

const useProject = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [search, setSearch] = useState({
    searchString: "",
    project: {},
    page: 1,
  });
  const [projects, setProjects] = useState([]);
  const [errors, setErrors] = useState({});
  const [nbPages, setNbPages] = useState(1);

  const searchProjects = () => {
    setIsLoading(true);
    dataService.post(
      `projects/search`,
      search,
      (datas) => {
        setProjects(datas.data);
        setNbPages(Math.ceil(datas.count / 25));
      },
      setErrors,
      () => setIsLoading(false)
    );
  };

  useEffect(() => {
    searchProjects();
  }, []);

  useEffect(() => {
    clearTimeout(searchTimeout);
    if (isFirstLoad) {
      isFirstLoad = false;
    } else {
      searchTimeout = setTimeout(() => {
        searchProjects();
      }, 1500);
    }
  }, [search]);

  const removeProject = (id) => {
    setIsRemoving(id);
    setTimeout(() => {
      setIsRemoving(false);
    }, 2000);
    dataService.remove(
      `projects/${id}`,
      {},
      (datas) => {
        toast.success("Le projet a bien été supprimé");
        searchProjects();
      },
      (err) => {}
    );
  };

  return {
    search,
    setSearch,
    searchProjects,
    projects,
    isLoading,
    setIsLoading,
    nbPages,
    removeProject,
    isRemoving,
  };
};

export default useProject;
