import { useState } from "react";
import useStoreState from "../../Context/useStoreState";
import ClientForm from "./ClientForm";
import ProjectForm from "./ProjectForm";
import ClientInfos from "./ClientInfos";

const InformationsView = () => {
  const { state, items, items_actions } = useStoreState();
  const { project } = items;
  const { client } = project;
  const [showForm, setShowForm] = useState(false);

  return (
    <div className="row">
      <div className="col-12 col-md-6" data-priv="create_projet">
        <ProjectForm showForm={showForm} setShowForm={setShowForm} />
      </div>

      <div className="col-12 col-md-6">
        {project.client && <ClientInfos />}
        {showForm && <ClientForm />}
      </div>
    </div>
  );
};

export default InformationsView;
