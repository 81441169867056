import React, { useContext, useEffect } from "react";
import { withRouter } from "react-router";
import Page from "../../common/layout/Page";
import useStoreState from "./Context/useStoreState";

const ProjectDetailPage = withRouter((props) => {
  const { state, items, items_actions, gState } = useStoreState();
  const fetchId = props.match.params.id;

  console.log("state local", state);
  useEffect(() => {
    items_actions.initItems(props.match.params.id);
  }, []);

  const projectName = items.project?.name ? `« ${items.project.name} »` : "";

  return (
    <Page
      title={`${
        fetchId == "create" ? "Création" : "Modification"
      } du projet ${projectName}`}
      isLoading={state.isLoading}
      back={"/"}
    >
      {state.isInit ? props.children : null}
    </Page>
  );
});

export default ProjectDetailPage;
