import { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import dataService from "../../../../../helpers/dataService";
import useFile from "../../../../../hooks/useFile/useFile";
import useStoreState from "../../Context/useStoreState";

const useDevis = (fetchId) => {
  const { uploadFile, deleteFile } = useFile();
  const { state, items, items_actions } = useStoreState();
  const [isLoading, setIsLoading] = useState(false);

  const initialState = {
    url: "",
    project: fetchId,
  };

  const [quotation, setQuotation] = useState(initialState);

  const [errors, setErrors] = useState({});
  const [binary, setBinary] = useState(null);
  const [existingFile, setExistingFile] = useState(null);

  useEffect(() => {
    fetchQuotation();
    fetchDevisFile();
  }, []);

  const fetchQuotation = () => {
    dataService.get(`projects/${fetchId}/quotes`, (datas) =>
      setQuotation(datas[0] || initialState)
    );
  };

  const saveDevis = () => {
    setIsLoading(true);
    if (quotation.id) {
      dataService.patch(
        `quotes/${quotation.id}`,
        quotation,
        (datas) => {
          setQuotation(datas);
          toast.success("Le lien a bien été enregistré");
        },
        setErrors,
        () => setIsLoading(false)
      );
    } else {
      dataService.post(
        `quotes`,
        quotation,
        (datas) => {
          setQuotation(datas);
          toast.success("Le lien a bien été enregistré");
        },
        setErrors,
        () => setIsLoading(false)
      );
    }
  };

  const fetchDevisFile = () => {
    setIsLoading(true);
    dataService.get(
      `projects/${fetchId}/files/quote`,
      (datas) => {
        setExistingFile(datas[0]);
        items_actions.updateDevis(
          datas.map((data) => ({ file: { ...data, type: data.fileType } }))
        );
      },
      setErrors,
      () => setIsLoading(false)
    );
  };

  const saveDevisFile = () => {
    setIsLoading(true);
    uploadFile({
      category: "quote",
      binary,
      type: "PDF",
      entity: fetchId,
      name: `Devis du projet ${items.project.name}`,
      onUploaded: (datas) => {
        fetchDevisFile();
      },
      onError: setErrors,
      onFinished: () => setIsLoading(false),
    });
  };

  const deleteDevisFile = () => {
    setIsLoading(true);
    setTimeout(() => setIsLoading(false), 3000);
    deleteFile({
      id: existingFile.id,
      onRemoved: (datas) => fetchDevisFile(),
      onError: setErrors,
      onFinished: () => setIsLoading(),
    });
  };

  return {
    isLoading,
    setIsLoading,
    quotation,
    setQuotation,
    binary,
    setBinary,
    saveDevis,
    saveDevisFile,
    deleteDevisFile,
    existingFile,
  };
};

export default useDevis;
