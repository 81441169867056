import { withRouter } from "react-router";
import { BarLoader } from "react-spinners";
import colors from "../../../../../helpers/colors";
import PaymentForm from "./PaymentForm";
import PaymentList from "./PaymentList";
import PaymentRecap from "./PaymentRecap";
import usePayments from "./usePayments";

const PaiementsView = withRouter((props) => {
  const fetchId = props.match.params.id;
  const {
    isLoading,
    setIsLoading,
    payments,
    setPayments,
    actions,
    newPayment,
    paymentAccounts,
    setNewPayment,
    savePayment,
    errors,
    paymentStatuses,
    paymentMethods,
    idsChanged,
  } = usePayments(fetchId);

  return (
    <div className="row p-2 bg-control">
      <div className="col-12">
        <PaymentList
          payments={payments}
          actions={actions}
          paymentStatuses={paymentStatuses}
          paymentMethods={paymentMethods}
          idsChanged={idsChanged}
          isLoading={isLoading}
        />
      </div>
      <div className="col-12">
        <div className="custom-card">
          <PaymentRecap payments={payments} />
        </div>
      </div>
      <div className="col-12 mt-3">
        <div className="custom-card">
          <PaymentForm
            newPayment={newPayment}
            setNewPayment={setNewPayment}
            errors={errors}
            paymentAccounts={paymentAccounts}
            paymentStatuses={paymentStatuses}
            paymentMethods={paymentMethods}
            isLoading={isLoading}
            actions={actions}
          />
        </div>
      </div>
      {isLoading && (
        <div
          style={{
            position: "fixed",
            bottom: 0,
            right: 0,
            zIndex: "9999",
            width: "100%",
          }}
        >
          <BarLoader height={8} width={"100%"} color={colors.secondary} />
        </div>
      )}
    </div>
  );
});

export default PaiementsView;
