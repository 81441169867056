import { useEffect, useState } from "react";
import Control from "../../../../common/Controls/Control";
import SaveBtn from "../../../../common/SaveBtn/SaveBtn";

const PaymentForm = ({
  newPayment,
  setNewPayment,
  errors,
  paymentAccounts,
  paymentStatuses,
  paymentMethods,
  actions,
  isLoading,
}) => {
  const [isRogue, setIsRogue] = useState(false);

  const toggleRogue = (checked) => {
    if (!checked) {
      setNewPayment({
        ...newPayment,
        paymentMethod: null,
        providerData: {
          accountKey: paymentAccounts[0].id
        }
      })
    } else {
      setNewPayment({
        ...newPayment,
        providerData: undefined,
      })
    }
    setIsRogue(checked);
  }

  return (
    <div>
      <div>
        <strong>Ajouter une échéance</strong>
      </div>
      <Control
        label="Libellé"
        name="name"
        type="text"
        value={newPayment.name}
        change={(e) => setNewPayment({ ...newPayment, name: e.target.value })}
        error={errors}
      />
      <Control
        label="Montant"
        name="amount"
        type="float"
        value={newPayment.amount}
        change={(e) => setNewPayment({ ...newPayment, amount: e.target.value })}
        error={errors}
      />
      <Control
        label="Ce paiement est externe (réalisé en dehors de Stripe)"
        name="amount"
        type="checkbox"
        checked={isRogue}
        change={(e) => toggleRogue(e.target.checked)}
        error={errors}
      />
      {!isRogue && <Control
        label="Compte Stripe"
        type="btnList"
        datas={paymentAccounts}
        value={newPayment.providerData?.accountKey}
        dataIndex="id"
        dataLabel="name"
        btnInline
        change={(e) =>
          setNewPayment({...newPayment, providerData: { accountKey: e.target.value}})
        }
        error={errors}
      />}
      {isRogue && (
        <>
          <Control
            label="Statut du paiement"
            name="status"
            type="btnList"
            datas={paymentStatuses}
            value={newPayment.status}
            dataIndex="id"
            dataLabel="name"
            btnInline
            change={(e) =>
              setNewPayment({ ...newPayment, status: e.target.value })
            }
            error={errors}
          />
          <Control
            label="Méthode de paiement"
            name="paymentMethod"
            type="btnList"
            datas={paymentMethods.filter((p) => !p.isStripe)}
            value={newPayment.paymentMethod}
            dataIndex="id"
            dataLabel="name"
            btnInline
            change={(e) =>
              setNewPayment({ ...newPayment, paymentMethod: e.target.value })
            }
            error={errors}
          />
        </>
      )}
      <div className="d-flex w-100 justify-content-end">
        <SaveBtn
          save={() => actions.saveNewPayment(newPayment)}
          isSaving={isLoading}
          text="Ajouter cette échéance"
        />
      </div>
    </div>
  );
};

export default PaymentForm;
