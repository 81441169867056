import React from "react";
import { Link } from "react-router-dom";
import useStore from "../../../../context/useStore";
import tools from "../../../../helpers/tools";
import Table from "../../../common/Table/Table";
const UserManagersTable = ({
  isLoading,
  userManagers,
  nbResults,
  removeUserManager,
}) => {
  const [state, dispatch] = useStore();
  const { MANAGER_ROLES } = state.constants.items;

  return (
    <Table
      isLoading={isLoading}
      nbResults={nbResults}
      datas={userManagers}
      fields={[
        {
          name: "Nom",
          path: "*",
          render: (el) => tools.getFullName(el),
        },
        {
          name: "Email",
          path: "email",
        },
        {
          name: "Rôle",
          path: "role",
          render: (el) =>
            MANAGER_ROLES.find((mr) => mr.id == el) ? (
              MANAGER_ROLES.find((mr) => mr.id == el).name
            ) : (
              <div style={{ fontSize: 20 }}>🥷</div>
            ),
        },
        {
          name: "Actions",
          path: "id",
          render: (el) => (
            <>
              <Link
                to={`/user-managers/${el}`}
                className="btn btn-primary d-inline-flex align-items-center justify-content-center"
              >
                Modifier
              </Link>
              {/* <button
                className="btn btn-danger d-inline-flex align-items-center justify-content-center"
                onClick={() => removeUserManager(el)}
              >
                Supprimer
              </button> */}
            </>
          ),
        },
      ]}
    />
  );
};

export default UserManagersTable;
