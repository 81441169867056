import { useEffect, useState } from "react";
import dataService from "../../../../helpers/dataService";

var searchTimeout = null;
var isFirstLoad = true;

const useUserManagersList = () => {
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [userManagers, setUserManagers] = useState([]);
  const [nbPages, setNbPages] = useState(1);
  const [nbResults, setNbResults] = useState(0);
  const [search, setSearch] = useState({
    page: 1,
    searchString: "",
    userManager: {},
  });

  useEffect(() => {
    searchUserManagers();
  }, []);

  useEffect(() => {
    clearTimeout(searchTimeout);
    if (isFirstLoad) {
      isFirstLoad = false;
    } else {
      searchTimeout = setTimeout(() => {
        searchUserManagers();
      }, 1500);
    }
  }, [search]);

  const searchUserManagers = () => {
    setIsLoading(true);
    dataService.post(
      "user-managers/search",
      search,
      (datas) => {
        setUserManagers(datas.data);
        setNbResults(datas.count);
        setNbPages(Math.ceil(datas.count / 25));
      },
      setErrors,
      () => {
        setIsLoading(false);
      }
    );
  };

  const removeUserManagers = (id) => {
    setIsLoading(true);
    dataService.remove(
      `user-managers/${id}`,
      {},
      (datas) => {
        searchUserManagers();
      },
      setErrors,
      () => {
        setIsLoading(false);
      }
    );
  };

  return {
    isLoading,
    setIsLoading,
    userManagers,
    setUserManagers,
    search,
    setSearch,
    actions: {
      search: searchUserManagers,
      remove: removeUserManagers,
    },
    nbPages,
    errors,
    setErrors,
  };
};

export default useUserManagersList;
