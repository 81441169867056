import React from "react";

const PaymentRecap = ({ payments }) => {
  return (
    <div className="row">
      <div className="col-12 col-md-6 d-center flex-column">
        <div>
          <strong>Paiements en attente</strong>
        </div>
        <div>
          {
            payments
              .filter((p) => p.status == "WAITING")
              .reduce(
                (a, b) => {
                  return { amount: a.amount + b.amount };
                },
                { amount: 0 }
              ).amount
          }{" "}
          €
        </div>
      </div>
      <div className="col-12 col-md-6 d-center flex-column">
        <div>
          <strong>Paiements effectués</strong>
        </div>
        <div>
          {
            payments
              .filter((p) => p.status == "DONE")
              .reduce(
                (a, b) => {
                  return { amount: a.amount + b.amount };
                },
                { amount: 0 }
              ).amount
          }{" "}
          €
        </div>
      </div>
    </div>
  );
};

export default PaymentRecap;
