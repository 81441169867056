const DownloadBlob = ({ blob, filename }) => {
  return (
    <a
      href={blob}
      target="_BLANK"
      title="télécharger"
      download={filename}
      className="d-center rounded-circle animated fadeIn"
      style={{
        background: "rgba(255,255,255,0.9)",
        position: "absolute",
        top: "85vh",
        right: "2%",
        width: 50,
        height: 50,
        textDecoration: "none",
      }}
    >
      <i
        className="fa fa-cloud-download-alt "
        style={{
          fontSize: 22,
        }}
      />
    </a>
  );
};

export default DownloadBlob;
