const initialState = {
  isLoading: false,
  items: {},
  actionUrls: {},
  errors: {},
  isInit: false,
};

const initItems = (payload, state) => {
  const { items, actionUrls } = payload;
  return { ...state, items, actionUrls, isLoading: false, isInit: true };
};

// const addItem = (item, state) => {
//   const newItem = [...state.items, item];
//   return { ...state, items: newItem };
// };
// const removeItem = (idx, state) => {
//   const newItems = [...state.items].filter((t, tk) => tk != idx);
//   return { ...state, items: newItems };
// };
const updateItem = ({ item, value }, state) => {
  return { ...state, items: { ...state.items, [item]: value } };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "IS_LOADING":
      return { ...state, isLoading: action.payload };
    case "IS_LOADING_PROJECT":
      return { ...state, isLoadingProject: action.payload };
    case "IS_LOADING_NEW_CLIENT":
      return { ...state, isLoadingNewClient: action.payload };
    case "INIT_ITEMS":
      return initItems(action.payload, state);
    case "IS_INIT":
      return { ...state, isInit: action.payload };

    case "UPDATE_ITEM":
      return updateItem(action.payload, state);
    case "UPDATE_ERRORS":
      return { ...state, items: { ...state.items, errors: action.payload } };
    case "UPDATE_DOCUMENTS":
      return { ...state, items: { ...state.items, documents: action.payload } };
    case "UPDATE_ANNEXES":
      return { ...state, items: { ...state.items, annexes: action.payload } };
    case "UPDATE_DEVIS":
      return { ...state, items: { ...state.items, devis: action.payload } };
    case "UPDATE_CLIENTS":
      return {
        ...state,
        items: {
          ...state.items,
          clients: action.payload,
        },
      };
    case "UPDATE_PROJECT":
      return {
        ...state,
        isLoadingProject: false,
        items: {
          ...state.items,
          project: {
            ...state.items.project,
            ...action.payload,
          },
        },
      };
    case "UPDATE_NEW_CLIENT":
      return {
        ...state,
        isLoadingProject: false,
        isLoadingNewClient: false,
        items: {
          ...state.items,
          newClient: {
            ...state.items.newClient,
            ...action.payload,
          },
        },
      };
    case "UPDATE_NEEDS_FORMS":
      return {
        ...state,
        isLoadingProject: false,
        isLoadingNewClient: false,
        items: {
          ...state.items,
          needsForms: action.payload,
        },
      };
    case "UPDATE_CURRENT_PANEL":
      return {
        ...state,
        items: {
          ...state.items,
          utils: { ...state.items.utils, currentPanel: action.payload },
        },
      };
    default:
      return state;
  }
};
