import React, { useRef, useEffect, useState } from "react";
import LabelContainer from "../LabelContainer";
import $ from "jquery";

const AutocompleteInput = ({
  k = -1,
  autoFocus,
  autocomplete = "on",
  inputStyle = {},
  inputClassName = "",
  disabled = false,
  id = null,
  value,
  change = () => {},
  placeholder = "",
  datas = [],
  autoCompleteDisplayIndex = false,
  dataIndex = "id",
  dataLabel = "name",
  dataLabel2 = false,
  dataLabel3 = false,
  dataLabelRight = false,
  dataLabelBtn1 = false,
  dataLabelBtn2 = false,
  hideAutocompleteOnFocusOut = false,
  absoluteAutocomplete = false, //buggé
  isLoading = false,
  noResults = false,
  autoCompleteHasApiDatas = false,
  ...props
}) => {
  const inputRef = useRef(null);
  const fieldRef = useRef(null);
  const name = props.name;
  const [autoCompleteEntrySelected, setAutoCompleteEntrySelected] = useState(
    false
  );
  const [searchStr, setSearchStr] = useState("");
  const [acInput, setAcInput] = useState(value);
  const [acResults, setAcResults] = useState([]);
  const [formatedValue, setFormatedValue] = useState("");
  var selector = k != -1 ? name + "-ac" + k : id ? id : name;

  const valueChange = (e, value) => {
    var filteredValue = value;

    setAutoCompleteEntrySelected(value == "" ? false : true);
    let changeEvent = {
      target: {
        name: props.name,
        value: filteredValue,
        k: k,
      },
    };

    change(changeEvent);
  };

  useEffect(() => {
    if (value && value != "") setAutoCompleteEntrySelected(true);
    else setAutoCompleteEntrySelected(false);
  }, [value]);

  useEffect(() => {
    seekAutocomplete();
  }, [searchStr]);

  const onFocus = () => {
    showAutocomplete();
  };
  const onBlur = () => {
    if (inputRef.current && hideAutocompleteOnFocusOut) {
      hideAutocomplete();
    }
  };

  useEffect(() => {
    seekAutocomplete();
    showAutocomplete();
  }, [datas]);

  function seekAutocomplete() {
    var acs = [];
    datas.forEach((d) => {
      if (
        autoCompleteHasApiDatas ||
        (d[dataLabel] &&
          d[dataLabel].toLowerCase().includes(searchStr.toLowerCase())) ||
        (d[dataLabel2] &&
          d[dataLabel2].toLowerCase().includes(searchStr.toLowerCase())) ||
        (d[dataLabel3] &&
          d[dataLabel3].toLowerCase().includes(searchStr.toLowerCase()))
      ) {
        acs.push(d);
      }
    });

    setAcResults(acs);
  }
  function hideAutocomplete() {
    inputRef.current.style.display = "none";
  }
  function showAutocomplete() {
    inputRef.current.style.display = "block";
  }
  function getCompositeData(obj, key) {
    var comp = key ? key.split(".") : [""];
    if (comp.length > 1) {
      return obj[comp[0]][comp[1]];
    }

    return obj[comp[0]];
  }

  const selectedEntry = datas.find((d) => d[dataIndex] == value);

  return (
    <LabelContainer {...props} value={value}>
      <div className=" w-100">
        <input
          ref={fieldRef}
          onFocus={onFocus}
          onBlur={onBlur}
          className={"form-control " + inputClassName}
          disabled={disabled ? "disabled" : false}
          autoFocus={autoFocus}
          value={searchStr}
          name={name}
          id={selector}
          onChange={(e) => setSearchStr(e.target.value)}
          placeholder={placeholder}
          autoComplete={autocomplete}
        />
        <button
          className={"btn text-dark position-absolute p-0"}
          onClick={(e) => {
            fieldRef.current.focus();
            setSearchStr("");
            valueChange(e, "");
          }}
          style={{
            opacity: searchStr != "" || value != "" ? "1" : "0",

            width: 30,
            height: 33,
            right: 0,
            minHeight: "auto",
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: "99",
          }}
        >
          <i className="fa fa-times" />
        </button>
      </div>
      {autoCompleteEntrySelected && (
        <button
          id={"btn-over-" + selector}
          className="btn  text-left position-absolute w-100 px-2 border-0"
          style={{
            padding: "0 10px",
            color: "black",
            borderRadius: 3,
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            minHeight: 0,
            bottom: 0,
            cursor: "pointer",
            position: "relative",
            left: 0,
            height: 30,
            background: "#fbfafa",
          }}
          onClick={(e) => {
            setAutoCompleteEntrySelected(false);
            valueChange(e, "");
            $("#btn-over-" + selector).hide();
            fieldRef.current.focus();
          }}
        >
          {autoCompleteDisplayIndex && selectedEntry ? (
            <>
              <b className="mr-2">{selectedEntry[autoCompleteDisplayIndex]}</b>
              {dataLabel2 && (
                <> {getCompositeData(selectedEntry, dataLabel2)} </>
              )}
              <span className="ml-2" style={{ fontSize: 12 }}>
                {getCompositeData(selectedEntry, dataLabelBtn1)}{" "}
                {getCompositeData(selectedEntry, dataLabelBtn2)}
              </span>
            </>
          ) : value ? (
            value
          ) : (
            ""
          )}
        </button>
      )}

      {(acResults.length > 0 || isLoading) && !autoCompleteEntrySelected && (
        <div className="w-100 position-relative embeded-forminfo" style={{}}>
          {isLoading
            ? "Recherche en cours..."
            : autoCompleteEntrySelected
            ? ""
            : "Sélectionnez parmis les résultats"}
        </div>
      )}
      {noResults && (
        <>
          <div
            className="w-100 position-relative"
            style={{
              margin: "60px 0 30px 0",
              left: -60,
            }}
          >
            Aucun résultat trouvé
          </div>
          <button
            className="btn btn-default"
            onClick={(e) => {
              valueChange(e, "");
              var bid = selector;
              $("#" + bid).focus();
            }}
          >
            Modifier ma recherche
          </button>
        </>
      )}
      <div
        ref={inputRef}
        className={
          "ac-results shadow  fast " +
          (absoluteAutocomplete ? " position-absolute " : " ")
        }
        id={"acres" + selector}
        style={{
          top: 52,
          left: 0,
          padding: 0,
          width: "100%",
          zIndex: "99999",
          display: "none",
          borderRadius: window.borderRadius,
          // boxShadow: "0px 2px 8px -5px black",
          overflowY: "scroll",
          overflowX: "hidden",
          height: autoCompleteEntrySelected ? 0 : 96,
          position: "absolute",
        }}
      >
        {acResults.map((a, ak) => {
          if (ak >= 5) return null;
          return (
            <div
              className="ac-res text-dark d-flex align-items-center flex-row justify-content-between animated fadeInDown faster"
              style={{
                padding: autoCompleteEntrySelected ? 0 : "5.5px 9px",
                height: autoCompleteEntrySelected ? "0px" : "auto",
                opacity: autoCompleteEntrySelected ? "0" : "1",

                width: "100%",
                cursor: "pointer",
                animationDelay: ak * 80 + "ms",
                overflow: "hidden",
                transition: "400ms",
              }}
              onClick={(e) => valueChange(e, a[dataIndex] + "")}
              key={"acres" + ak + "" + k}
            >
              <div className="d-flex flex-column" style={{ fontSize: 12 }}>
                <div>
                  <b>{getCompositeData(a, dataLabel)}</b>{" "}
                  {dataLabel2 && " " + getCompositeData(a, dataLabel2)}
                  {dataLabel3 && " " + getCompositeData(a, dataLabel3)}
                </div>
                <div
                  style={{
                    fontSize: 10,
                  }}
                >
                  {dataLabelBtn1 && " " + getCompositeData(a, dataLabelBtn1)}
                  {dataLabelBtn2 && " " + getCompositeData(a, dataLabelBtn2)}
                </div>
              </div>
              <div
                style={{
                  fontSize: 12,
                }}
              >
                {dataLabelRight ? getCompositeData(a, dataLabelRight) : ""}
              </div>
            </div>
          );
        })}
      </div>
    </LabelContainer>
  );
};

export default AutocompleteInput;
