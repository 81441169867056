import Page from "../../../common/layout/Page";
import SaveBtn from "../../../common/SaveBtn/SaveBtn";
import ModifyPasswordForm from "./ModifyPasswordForm";
import UserManagerDetailForm from "./UserManagerDetailForm";
import useUserManagersDetail from "./useUserManagersDetail";

const UserManagersDetail = (props) => {
  var userId = props.match.params.id;
  var isEdit = userId == "create" ? false : true;

  const {
    errors,
    isLoading,
    userManager,
    setUserManager,
    actions,
  } = useUserManagersDetail(userId, isEdit);

  return (
    <Page
      title={`${isEdit ? "Modification" : "Création"} de l'utilisateur`}
      back="/user-managers"
      errors={errors}
      isLoading={isLoading}
    >
      <div className="custom-card">
        <UserManagerDetailForm
          userManager={userManager}
          setUserManager={setUserManager}
          errors={errors}
        />
        <div className="d-flex justify-content-end mt-3">
          <SaveBtn
            isSaving={isLoading}
            text="Enregistrer"
            save={actions.saveUserManager}
          />
        </div>
      </div>
      {/* {userManager.id && (
        <div className="custom-card mt-3">
          <ModifyPasswordForm
            userManagerId={userManager.id}
            savePassword={actions.savePassword}
            errors={errors}
            isLoading={isLoading}
          />
        </div>
      )} */}
    </Page>
  );
};

export default UserManagersDetail;
