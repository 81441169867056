import useStoreState from "../Context/useStoreState";

const Panels = () => {
  const { items, items_actions } = useStoreState();

  const { panels, currentPanel } = items.utils;

  return (
    <div className="d-flex flex-row flex-wrap bg-default">
      {panels.map((panel) => (
        <button
          onClick={() => items_actions.setCurrentPanel(panel.id)}
          key={`panel${panel.id}`}
          data-priv={
            panel.id == "paiements"
              ? "view_payments"
              : panel.id == "devis"
              ? "view_quote"
              : ""
          }
          className={`btn d-center flex-fill ${
            currentPanel == panel.id ? "btn-primary" : "btn-default"
          }`}
        >
          {panel.name}
        </button>
      ))}
    </div>
  );
};

export default Panels;
