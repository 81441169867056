import React, { useEffect, useState } from "react";
import Control from "../../../../common/Controls/Control";
import FileUpload from "../../../../common/FileUpload/FileUpload";
import SaveBtn from "../../../../common/SaveBtn/SaveBtn";

const DocumentForm = ({
  isLoading,
  setNewFile,
  newFile,
  saveFiles,
  fileTypes,
  errors,
}) => {
  return (
    <div className="row">
      <div className="col-12">
        <div>
          <strong>Ajouter un fichier</strong>
        </div>
      </div>
      <div className="col-12 col-md-6">
        <Control
          label="Nom du fichier"
          type="text"
          name="name"
          value={newFile.name}
          change={(e) => setNewFile({ ...newFile, name: e.target.value })}
          error={errors}
        />
      </div>
      <div className="col-12 col-md-6">
        <Control
          label="Type de fichier"
          type="btnList"
          btnInline
          datas={fileTypes}
          dataIndex="id"
          dataLabel="name"
          name="type"
          value={newFile.type}
          change={(e) => setNewFile({ ...newFile, type: e.target.value })}
          error={errors}
        />
      </div>

      {newFile.type == "VIDEO" ? (
        <div className="col-12">
          <Control
            label="Id de la vidéo youtube"
            type="text"
            name="binary"
            value={newFile.binary}
            change={(e) => setNewFile({ ...newFile, binary: e.target.value })}
            error={errors}
          />
        </div>
      ) : (
        <>
          <div className="col-12 col-md-6">
            <div>
              <label>
                <span className="label-text">Version watermarkée</span>
              </label>
            </div>

            <FileUpload
              newBinary={newFile.binaryTemp}
              clearNewFile={() => setNewFile({ ...newFile, binaryTemp: null })}
              onFileDrop={(file) =>
                setNewFile({ ...newFile, binaryTemp: file })
              }
            />
          </div>
          <div className="col-12 col-md-6">
            <div>
              <label>
                <span className="label-text">Version de production</span>
              </label>
            </div>

            <FileUpload
              newBinary={newFile.binary}
              clearNewFile={() => setNewFile({ ...newFile, binary: null })}
              onFileDrop={(file) => setNewFile({ ...newFile, binary: file })}
            />
          </div>
        </>
      )}
      <div className="col-12">
        <div className="d-flex justify-content-end">
          <SaveBtn
            save={saveFiles}
            isSaving={isLoading}
            text="Envoyer les fichiers"
          />
        </div>
      </div>
    </div>
  );
};

export default DocumentForm;
