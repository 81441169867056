import Page from "../../common/layout/Page";
import useStore from "../../../context/useStore";
import ProjectList from "./ProjectList/ProjectList";
import useProject from "./ProjectList/useProject";
import ProjectFilters from "./ProjectFilters";
import { Link } from "react-router-dom";
import Pagination from "../../common/Pagination/Pagination";

const Dashboard = () => {
  const [state, dispatch] = useStore();

  const {
    search,
    setSearch,
    searchProjects,
    projects,
    isLoading,
    setIsLoading,
    errors,
    nbPages,
    removeProject,
    isRemoving,
  } = useProject();

  return (
    <Page title="Liste des projets" errors={errors} isLoading={isLoading}>
      <Link
        className="btn btn-primary d-inline-flex align-items-center"
        to="projects/create"
        data-priv="create_projet"
      >
        <i className="fa fa-plus mr-2" />
        Créer un nouveau projet
      </Link>
      <ProjectFilters
        search={search}
        setSearch={setSearch}
        searchProjects={searchProjects}
        isLoading={isLoading}
      />
      <ProjectList
        props={{
          search,
          setSearch,
          searchProjects,
          projects,
          isLoading,
          setIsLoading,
          errors,
          removeProject,
          isRemoving,
        }}
      />
      <Pagination
        nbPages={nbPages}
        page={search.page}
        changePage={(p) => setSearch({ ...search, page: p })}
      />
    </Page>
  );
};

export default Dashboard;
