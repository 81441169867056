import React, { createRef, useState } from "react";
import Control from "../Controls/Control";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Circle,
  useMap,
} from "react-leaflet";
import Geocode from "react-geocode";
import SaveBtn from "../SaveBtn/SaveBtn";

function MyMap({ position }) {
  const map = useMap();
  map.setView(position, map.getZoom());
  return null;
}

function GeocodeInput() {
  const [errors, setErrors] = useState({});
  const [address, setAddress] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [position, setPosition] = useState({
    lat: 0,
    lng: 0,
  });

  const [zoom, setZoom] = useState(12);
  function getPosFromAddress() {
    setIsLoading(true);
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);
    Geocode.fromAddress(address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        setPosition({ lat, lng });
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
      }
    );
  }

  return (
    <div className="col-12 animated fadeInUp">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          getPosFromAddress();
        }}
      >
        <Control
          name="address"
          type="text"
          label="Entrez une adresse"
          value={address}
          change={(e) => setAddress(e.target.value)}
          error={errors}
          suffix={
            <SaveBtn
              isSaving={isLoading}
              hideTextOnLoading
              text={<i className="fa fa-search" />}
              type="primary"
              save={getPosFromAddress}
            />
          }
        />
      </form>
      <MapContainer
        center={position}
        zoom={zoom}
        style={{ height: 300 }}
        scrollWheelZoom={true}
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={position}>
          <Popup>{address}</Popup>
        </Marker>
        <MyMap position={position} address={address} zoom={zoom} />
      </MapContainer>
    </div>
  );
}

export default GeocodeInput;
