import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import dataService from "../../../../../helpers/dataService";
import useStore from "../../../../../context/useStore";

const usePayments = (fetchId) => {
  const [isLoading, setIsLoading] = useState(false);
  const [state, dispatch] = useStore();
  const { PAYMENT_STATUS, PAYMENT_METHODS, PAYMENT_ACCOUNTS } =
    state.constants.items;
  const paymentStatuses = PAYMENT_STATUS;
  const paymentMethods = PAYMENT_METHODS;

  const paymentAccounts = PAYMENT_ACCOUNTS;
  const [errors, setErrors] = useState({});
  const paymentInitialState = {
    name: "",
    amount: 0,
    status: "WAITING",
    paymentMethod: undefined,
    providerData: {
      accountKey: paymentAccounts[0].id,
    },
    project: fetchId,
  };
  const [payments, setPayments] = useState([]);
  const [newPayment, setNewPayment] = useState(paymentInitialState);
  const [idsChanged, setIdsChanged] = useState([]);

  useEffect(() => {
    if (state.auth.user.role != "COLLABORATOR") fetchPayments();
  }, []);

  const fetchPayments = () => {
    setIsLoading(true);
    dataService.get(
      `projects/${fetchId}/payments`,
      (datas) => {
        setPayments(
          datas.map((dat) => ({
            ...dat,
            amount: Math.round((dat.amount / 100) * 100) / 100,
          }))
        );
      },
      setErrors,
      () => setIsLoading(false)
    );
  };

  const actions = {
    deletePayment: (id) => {
      setIsLoading(true);
      dataService.remove(`payments/${id}`, {}, fetchPayments, setErrors, () =>
        setIsLoading(false)
      );
    },
    updatePayment: (newPayment, e) => {
      var { name, value } = e.target;
      setPayments([
        ...payments.map((p) => {
          if (p.id == newPayment.id) {
            return {
              ...p,
              [name]: value,
            };
          }
          return p;
        }),
      ]);

      if (!idsChanged.includes(newPayment.id)) {
        setIdsChanged([...idsChanged, newPayment.id]);
      }
    },
    savePayment: (payment) => {
      console.log("SAVING IN LINE PAYMENT", payment);
      setIsLoading(true);
      dataService.patch(
        `payments/${payment.id}`,
        payment,
        (datas) => {
          fetchPayments();
          setIdsChanged(idsChanged.filter((ids) => ids != payment.id));
        },
        setErrors,
        () => setIsLoading(false)
      );
    },
    saveNewPayment: (payment) => {
      console.log("SAVING FORM", payment);
      setIsLoading(true);
      dataService.post(
        `payments`,
        payment,
        (datas) => {
          fetchPayments();
          setNewPayment(paymentInitialState);
          setIsLoading(false);
        },
        setErrors,
        () => setIsLoading(false)
      );
    },
  };

  return {
    isLoading,
    setIsLoading,
    payments,
    setPayments,
    fetchPayments,
    actions,
    newPayment,
    paymentAccounts,
    setNewPayment,
    errors,
    paymentStatuses,
    paymentMethods,
    idsChanged,
  };
};

export default usePayments;
