import { useEffect, useState } from "react";
import Control from "../../../../common/Controls/Control";
import SaveBtn from "../../../../common/SaveBtn/SaveBtn";
import useStoreState from "../../Context/useStoreState";

const FormulairesView = () => {
  const { state, items, items_actions, gState } = useStoreState();
  const { isLoadingNeedsForms } = state;
  const { errors, needsForms, templateForms } = items;
  const { constants } = gState;
  const services = constants.items.FORM_TYPES.sort((a, b) =>
    a.name > b.name ? 1 : -1
  );
  const [hiddenFormTypes, setHiddenFormTypes] = useState([]);
  const [selectedServices, setSelectedServices] = useState(
    needsForms.map((ns) => ns.type)
  );

  useEffect(() => {
    setHiddenFormTypes(selectedServices);
  }, [selectedServices]);

  //on crée un array contenant les formulaires des services sélectionnés
  const forms = selectedServices
    .map((ss) => templateForms.find((tf) => tf.type == ss))
    .map((ts) => {
      var refForm = needsForms.find((ns) => ns.type == ts.type);
      return (
        refForm || {
          ...ts,
          id: undefined,
          createdAt: undefined,
          updatedAt: undefined,
        }
      );
    });

  const handleChange = (e, formType) => {
    const { type, name, value } = e.target;
    var newNeedsForms = [...forms];
    newNeedsForms = newNeedsForms.map((nf) => {
      if (nf.type == formType) {
        nf.inputs.find((inp) => inp.name == name).value = value;
      }
      return nf;
    });
    items_actions.updateNeedsForms(newNeedsForms);
  };

  const saveNeedsForm = (form) => {
    items_actions.saveNeedsForm(form);
  };

  const toggleHiddenFormTypes = (type) => {
    if (hiddenFormTypes.includes(type)) {
      setHiddenFormTypes(hiddenFormTypes.filter((h) => h != type));
    } else {
      setHiddenFormTypes([...hiddenFormTypes, type]);
    }
  };

  return (
    <div className="row">
      <div className="col-12" data-priv="create_needs_form">
        <div className="custom-card">
          <Control
            label="Services concernés"
            name="service"
            type="checkBtnList"
            btnInline
            dataIndex="id"
            dataLabel="name"
            value={selectedServices}
            change={(e) => setSelectedServices(e.target.value)}
            datas={services}
          />
        </div>
      </div>

      {forms.map((form, formk) => {
        const serviceName = services.find((s) => s.id == form.type).name;
        return (
          <div
            key={`formneed${formk}`}
            className={`col-12 col-md-6 my-3 vivify fadeInUp delay-${
              formk * 300
            }`}
            key={`formtemp${form.type}`}
          >
            <div className="">
              <div
                className="text-center bg-primary text-white position-relative py-3 cursor-pointer"
                onClick={() => toggleHiddenFormTypes(form.type)}
              >
                <strong style={{ fontSize: 22 }}>{serviceName}</strong>
                {form.id && (
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 5,
                    }}
                  >
                    <small>enregistré</small>
                    <i className="fa fa-check ml-1" />
                  </div>
                )}
              </div>
              {form.sections.map((sec, seck) => (
                <div
                  key={`formneedsecto${formk}${seck}`}
                  className=""
                  style={{
                    maxHeight: hiddenFormTypes.includes(form.type) ? 0 : 1200,
                    overflow: "hidden",
                    transition: "400ms",
                  }}
                >
                  <div
                    className="bg-dark text-white font-weight-bold p-2"
                    style={{
                      fontSize: 18,
                    }}
                  >
                    {sec}
                  </div>
                  <div className="custom-card">
                    {form.inputs
                      .filter((inp) => inp.section == seck)
                      .map((input, inpk) => (
                        <div className="mb-3" key={`inp${formk}${seck}${inpk}`}>
                          <Control
                            change={(e) => handleChange(e, form.type)}
                            {...input}
                            btnInline
                          />
                        </div>
                      ))}
                  </div>
                </div>
              ))}
            </div>
            <div
              className="d-flex justify-content-end"
              style={{
                maxHeight: hiddenFormTypes.includes(form.type) ? 0 : 1200,
                overflow: "hidden",
              }}
              data-priv="create_needs_form"
            >
              <SaveBtn
                isSaving={isLoadingNeedsForms}
                save={() => saveNeedsForm(form)}
                text={`Enregister "${serviceName}"`}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default FormulairesView;
