import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import dataService from "../../../../helpers/dataService";

const useUserManagersDetail = (userId, isEdit) => {
  const [errors, setErrors] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userManager, setUserManager] = useState({
    firstname: "",
    lastname: "",
    email: "",
    role: "",
    password: "",
  });

  useEffect(() => {
    if (isEdit) {
      getUserManager();
    }
  }, []);

  const getUserManager = () => {
    setIsLoading(true);
    dataService.get(`user-managers/${userId}`, setUserManager, setErrors, () =>
      setIsLoading(false)
    );
  };

  const saveUserManager = () => {
    setIsLoading(true);
    if (isEdit) {
      dataService.patch(
        `user-managers/${userId}`,
        userManager,
        (datas) => {
          setUserManager(datas);
          toast.success("L'utilisateur a bien été modifié");
        },
        setErrors,
        () => setIsLoading(false)
      );
    } else {
      dataService.post(
        `user-managers`,
        userManager,
        (datas) => {
          toast.success("L'utilisateur a bien été créé");
          window.location.href = "/user-managers/";
        },
        setErrors,
        () => setIsLoading(false)
      );
    }
  };

  const savePassword = (password) => {
    dataService.patch(
      `user-managers/${userId}/password`,
      { password },
      (datas) => {
        setUserManager(datas);
        toast.success("Le mot de passe de l'utilisateur a bien été modifié");
      },
      setErrors,
      () => setIsLoading(false)
    );
  };

  return {
    errors,
    isLoading,
    userManager,
    setUserManager,
    actions: {
      saveUserManager,
      getUserManager,
      savePassword,
    },
  };
};

export default useUserManagersDetail;
