import React, { useState, useEffect } from "react";
import "./cardlist.scss";
import ExportCSV from "../ExportCsv/ExportCsv";
import Loader from "../Loader";

const CardList = ({
  datas,
  fields,
  exportOptions,
  isLoading,
  noMargin = false,
  nbResults = null,
  lineBackgroundFromAttribute = false,
  lineColor = false,
  cardBg = "",
}) => {
  return (
    <div className="card-list">
      {exportOptions && (
        <div
          className={`d-flex justify-content-end animated faster mb-2 ${
            isLoading ? "fadeOut" : "fadeIn"
          }`}
        >
          <ExportCSV
            fields={fields}
            datas={datas}
            exportOptions={exportOptions}
          />
        </div>
      )}
      {nbResults !== null && (
        <div className="d-flex align-items-center justify-content-end">
          <div className="badge text-secondary" style={{ fontSize: 12 }}>
            {isLoading ? (
              <Loader color="secondary" size={12} />
            ) : (
              <>
                {nbResults} résultat{nbResults > 0 ? "s" : ""}
              </>
            )}
          </div>
        </div>
      )}
      <div className={`pt-0  w-100  ${cardBg}`}>
        <div className="d-start align-items-start flex-fill flex-wrap">
          {datas &&
            datas.map((d, dk) => (
              <section
                key={"dk" + dk}
                className="animated fadeInUp faster d-start flex-column custom-card mb-3 h-100 flex-fill mx-1"
                style={{
                  animationDelay: dk * 30 + "ms",
                }}
              >
                {fields.map((f, fk) => (
                  <article
                    className="d-center flex-column w-100 mb-3"
                    style={{
                      background: lineBackgroundFromAttribute
                        ? d[lineBackgroundFromAttribute]
                        : "",
                      color: lineColor || "",
                    }}
                    key={"df" + dk + "" + fk}
                  >
                    {f.showName && (
                      <div className="field-name text-primary w-100 d-center">
                        <span>{f.name}</span>
                      </div>
                    )}
                    {f.render
                      ? f.render(f.path == "*" ? d : d[f.path], dk)
                      : d[f.path]}
                  </article>
                ))}
              </section>
            ))}
        </div>
      </div>
    </div>
  );
};

export default CardList;
