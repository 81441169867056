import { DateTime } from "luxon";
import FileUpload from "../../../../common/FileUpload/FileUpload";
import Table from "../../../../common/Table/Table";
import VideoItem from "../../../../common/VideoItem/VideoItem";
import useStoreState from "../../Context/useStoreState";
import tools from "../../../../../helpers/tools";
import CardList from "../../../../common/CardList/CardList";

const AnnexeList = ({ existingFiles, setExistingFiles, deleteAnnexeFile }) => {
  const { gState } = useStoreState();
  const { FILE_TYPES } = gState.constants.items;

  const clearFile = (file) => {
    setExistingFiles(
      existingFiles.map((ex) =>
        ex.id == file.id
          ? {
              ...ex,
              binary: null,
            }
          : ex
      )
    );
  };

  const addNewBinary = (file, binary) => {
    setExistingFiles(
      existingFiles.map((ex) =>
        ex.id == file.id
          ? {
              ...ex,
              binary,
            }
          : ex
      )
    );
  };

  return (
    <CardList
      datas={existingFiles}
      fields={[
        {
          name: "Nom",
          path: "*",
          render: (el) => {
            var ft = FILE_TYPES.find((ft) => ft.id == el.fileType);
            return (
              <div className="text-center w-100">
                <div
                  className="text-default font-weight-bold"
                  style={{ fontSize: 22 }}
                >
                  {el.name}
                </div>
                <small>
                  <i className={`fa mr-1 fa-${ft.icon}`} />
                  {ft.name}
                </small>
              </div>
            );
          },
        },

        {
          name: "Date d'envoi",
          path: "*",
          render: (el) => (
            <div className="text-center">
              <div>
                {DateTime.fromISO(el.uploadedAt).toFormat("dd/MM/yyyy")}
              </div>
              <small>par {tools.getFullName(el.uploadedBy)}</small>
            </div>
          ),
        },

        {
          name: "Fichier",
          path: "*",
          render: (el) => {
            return (
              <div className="d-center flex-column w-100">
                {el.fileType == "VIDEO" ? (
                  <VideoItem
                    file={el}
                    deleteFile={() => deleteAnnexeFile(el.id)}
                  />
                ) : (
                  <FileUpload
                    existingFile={el}
                    newBinary={el.binary}
                    clearNewFile={() => clearFile(el)}
                    onFileDrop={addNewBinary}
                    deleteFile={() => deleteAnnexeFile(el.id)}
                  />
                )}
              </div>
            );
          },
        },
        {
          name: "Créé le",
          path: "createdAt",
          render: (el) => DateTime.fromISO(el).toFormat("dd/MM/yyyy"),
        },
      ]}
    />
  );
};

export default AnnexeList;
