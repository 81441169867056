import { useEffect, useState } from "react";
import dataService from "../../../../../helpers/dataService";
import useFile from "../../../../../hooks/useFile/useFile";
import useStoreState from "../../Context/useStoreState";

const useAnnexe = (fetchId) => {
  const { uploadFile, deleteFile } = useFile();
  const { state, items, items_actions } = useStoreState();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [existingFiles, setExistingFiles] = useState([]);
  const newFileInitialState = {
    name: "",
    type: "",
    binary: "",
  };
  const [newFile, setNewFile] = useState(newFileInitialState);
  const [binary, setBinary] = useState(null);

  useEffect(() => {
    fetchAnnexeFiles();
  }, []);

  const fetchAnnexeFiles = () => {
    setIsLoading(true);
    dataService.get(
      `projects/${fetchId}/files/annex`,
      (datas) => {
        setExistingFiles(datas);
        items_actions.updateAnnexes(
          datas.map((data) => ({ file: { ...data, type: data.fileType } }))
        );
      },
      setErrors,
      () => setIsLoading(false)
    );
  };

  const saveNewAnnexeFile = () => {
    setIsLoading(true);
    uploadFile({
      category: "annex",
      binary: newFile.binary,
      type: newFile.type,
      entity: fetchId,
      name: newFile.name,
      onUploaded: (datas) => {
        fetchAnnexeFiles();
        setNewFile(newFileInitialState);
      },
      onError: setErrors,
      onFinished: () => setIsLoading(false),
    });
  };
  const saveAnnexeFile = ({ name }) => {
    setIsLoading(true);
    uploadFile({
      category: "annex",
      binary,
      type: "PDF",
      entity: fetchId,
      name: name,
      onUploaded: (datas) => {
        fetchAnnexeFiles();
      },
      onError: setErrors,
      onFinished: () => setIsLoading(false),
    });
  };

  const deleteAnnexeFile = (id) => {
    setIsLoading(true);
    deleteFile({
      id,
      onRemoved: (datas) => fetchAnnexeFiles(),
      onError: setErrors,
      onFinished: () => setIsLoading(false),
    });
  };

  return {
    fetchAnnexeFiles,
    existingFiles,
    setExistingFiles,
    saveAnnexeFile,
    deleteAnnexeFile,
    binary,
    setBinary,
    newFile,
    setNewFile,
    saveNewAnnexeFile,
    isLoading,
  };
};

export default useAnnexe;
