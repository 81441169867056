const menuItems = [
  {
    name: "Projets",
    path: "/dashboard",
    icon: "pencil-ruler",
  },
  {
    name: "Utilisateurs",
    path: "/user-managers",
    icon: "user-tie",
    dataPriv: "create_user",
  },
  // {
  //   name: "Paramétrage",
  //   path: "#",
  //   icon: "caret-down",
  //   items: [
  //     {
  //       name: "Types de plat",
  //       path: "/dishtypes",
  //       icon: "fish",
  //     },
  //     {
  //       name: "Textures",
  //       path: "/textures",
  //       icon: "cookie",
  //     },
  //     {
  //       name: "Spécificités",
  //       path: "/specificities",
  //       icon: "cookie",
  //     },
  //     {
  //       name: "Référence plan alimentaire",
  //       path: "/slotcodes",
  //       icon: "cookie",
  //     },
  //   ],
  // },
  // {
  //   name: "Préparation",
  //   path: "#",
  //   icon: "caret-down",
  //   items: [
  //     {
  //       name: "Fiches techniques",
  //       path: "/technicalsheets",
  //       icon: "book",
  //     },
  //     {
  //       name: "Ingrédients",
  //       path: "/ingredients",
  //       icon: "pepper-hot",
  //     },
  //     {
  //       name: "Fournisseurs",
  //       path: "/providers",
  //       icon: "truck",
  //     },
  //   ],
  // },
  // {
  //   name: "Production",
  //   path: "#",
  //   icon: "caret-down",
  //   items: [
  //     {
  //       name: "Cuisines de production",
  //       path: "/productionsites",
  //       icon: "globe",
  //     },
  //     {
  //       name: "Menu de référence",
  //       path: "/menu-reference",
  //       icon: "calendar-alt",
  //     },
  //     {
  //       name: "Duplication de cycle",
  //       path: "/duplicate-cycle",
  //       icon: "calendar-alt",
  //     },
  //     {
  //       name: "Types de consommateur",
  //       path: "/consumertypes",
  //       icon: "street-view",
  //     },
  //   ],
  // },
  // {
  //   name: "Commandes",
  //   path: "#",
  //   icon: "caret-down",
  //   items: [
  //     {
  //       name: "Clients",
  //       path: "/clients",
  //       icon: "user",
  //     },
  //     {
  //       name: "Commandes",
  //       path: "/orders",
  //       icon: "truck",
  //     },
  //   ],
  // },
  // {
  //   name: "Récapitulatifs",
  //   path: "#",
  //   icon: "caret-down",
  //   items: [
  //     {
  //       name: "Production",
  //       path: "/sum-prod",
  //       icon: "file-signature",
  //     },
  //   ],
  // },
];
export default menuItems;
