import axios from "axios";
import React, { useEffect, useState } from "react";
import YouTube from "react-youtube-embed";
import { API_URL } from "../../../config";
import Loader from "../Loader";
import Comment from "./Comment";
import Download from "./Download";
import DownloadBlob from "./DownloadBlob";

const FileSlide = ({
  file,
  blobs,
  setBlobs,
  setIsGettingMedia,
  handleChange,
  isSavingComment,
  sendComment,
  updateCommentStatus,
  refetch,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [objectUrl, setObjectUrl] = useState(null);
  var existingObjectUrl = blobs.find((b) => b.fileId == file.id);
  const [fileId, setFileId] = useState(null);

  const updateBlobs = (blob) => {
    setBlobs(
      existingObjectUrl
        ? [...blobs.map((b) => (b.fileId == file.id ? { ...blob } : b))]
        : [...blobs, blob]
    );
  };

  const getMedia = ({ mimeType, callback }) => {
    setIsLoading(true);
    setIsGettingMedia(true);
    axios(`${API_URL}files/${file.id}/download`, {
      method: "GET",
      responseType: "blob", //Force to receive data in a Blob Format
    })
      .then((response) => {
        //Create a Blob from the PDF Stream
        callback(mimeType, response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .then(() => {
        setIsGettingMedia(false);
        setIsLoading(false);
      });
  };

  const onReceivePDF = (mimeType, binaryData) => {
    const fileBlob = new Blob([binaryData], { type: mimeType });
    updateBlobs({ fileId: file.id, mimeType, binaryData });
    const fileURL = URL.createObjectURL(fileBlob);
    setObjectUrl(fileURL);
  };

  const onReceiveImage = (mimeType, binaryData) => {
    const fileURL = URL.createObjectURL(binaryData);
    updateBlobs({ fileId: file.id, mimeType, binaryData });

    setObjectUrl(fileURL);
  };

  useEffect(() => {
    if (fileId != file.id) {
      setFileId(file.id);
      setObjectUrl(null);
      switch (file.type) {
        case "PDF":
          existingObjectUrl
            ? onReceivePDF(
                existingObjectUrl.mimeType,
                existingObjectUrl.binaryData
              )
            : getMedia({
                mimeType: "application/pdf",
                callback: onReceivePDF,
              });
          break;
        case "IMAGE":
          existingObjectUrl
            ? onReceiveImage(
                existingObjectUrl.mimeType,
                existingObjectUrl.binaryData
              )
            : getMedia({
                mimeType: "image/jpg",
                callback: onReceiveImage,
              });
          break;
      }
    }
  }, [file]);

  return (
    <>
      {isLoading || file.id != fileId ? (
        <div className="d-center w-100 h-100">
          <Loader />
        </div>
      ) : (
        <div className="w-100 h-100 d-center flex-column animated fadeIn p-5">
          {file.type == "IMAGE" ? (
            <img
              id="image-preview"
              src={objectUrl}
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          ) : file.type == "PDF" ? (
            <iframe
              type="application/pdf"
              style={{ width: "100%", height: "100%", border: 0 }}
              src={objectUrl}
            />
          ) : file.type == "VIDEO" ? (
            <div
              style={{
                width: "min(100% , 600px)",
              }}
            >
              <YouTube id={file.youtubeId} />
            </div>
          ) : (
            <Download file={file} />
          )}
          {!["VIDEO", "OTHER"].includes(file.type) && (
            <DownloadBlob
              blob={objectUrl}
              filename={`${file.name}${file.fileExt}`}
            />
          )}
          <Comment
            handleChange={handleChange}
            currentFile={file}
            isSavingComment={isSavingComment}
            sendComment={sendComment}
            updateCommentStatus={updateCommentStatus}
            refetch={refetch}
          />
        </div>
      )}
      <div
        className="text-primary d-center animated pulse slower infinite"
        style={{
          position: "absolute",
          top: 5,
          left: 20,
          height: 50,
        }}
      >
        LITHOTE
      </div>
    </>
  );
};

export default FileSlide;
