import { useState } from "react";
import { toast } from "react-toastify";
import dataService from "../../../../../helpers/dataService";
import SaveBtn from "../../../../common/SaveBtn/SaveBtn";
import useStoreState from "../../Context/useStoreState";

const ClientInfos = () => {
  const { state, items, items_actions } = useStoreState();
  const { project, clients } = items;
  const client = clients.find((c) => c.id == project.client);

  const [isSendingMail, setIsSendingMail] = useState(false);

  const sendActivationMail = () => {
    setIsSendingMail(true);
    dataService.get(
      `user-customers/${client.id}/activation-email`,
      (datas) => {
        toast.success("L'invitation a bien été envoyée");
      },
      (err) => {},
      () => setIsSendingMail(false)
    );
  };

  return (
    <div className="custom-card mb-3 vivify pullUp">
      <div>
        <strong>Client sélectionné</strong>
      </div>
      <div>
        {client.firstname} {client.lastname}
      </div>

      <div>{client.email}</div>
      <div>{client.phone}</div>
      <div>
        <div
          className={`${client.isActivated ? "text-success" : "text-danger"}`}
        >
          {client.isActivated ? "Email vérifié" : "Email non vérifié"}
          <i
            className={`fa ml-2 ${
              client.isActivated ? "fa-check" : "fa-times"
            }`}
          />
        </div>
      </div>
      <SaveBtn
        type="info"
        save={sendActivationMail}
        text="Envoyer l'email d'activation"
        isSaving={isSendingMail}
      />
    </div>
  );
};

export default ClientInfos;
