import axios from "axios";
import { API_URL, PLATFORM, recaptcha_token } from "../../config";
import { authActions } from "../../context/actions/authActions";
import { constantActions } from "../../context/actions/constantActions";
import useStore from "../../context/useStore";
import dataService from "../../helpers/dataService";
import jwt_decode from "jwt-decode";
import { useEffect } from "react";
import { loadReCaptcha } from "react-recaptcha-v3";

const useAuth = () => {
  const [state, dispatch] = useStore();
  const aActions = authActions(state, dispatch);
  const cActions = constantActions(state, dispatch);

  axios.defaults.withCredentials = true;
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.status == 401) {
        console.log("UNAUTHORIZED - login out", error.response.config.url);
        if (error.response.config.url !== API_URL + "auth/logout") {
          aActions.logoutUser(true);
        }
      }

      return Promise.reject(error);
    }
  );
  useEffect(() => {
    axios.get(`${API_URL}auth/me`).then((datas) => {
      const decoded = jwt_decode(datas.data);
      dispatch({
        type: "SET_CURRENT_USER",
        payload: decoded,
      });
    });
    // dataService.get(
    //   `auth/me`,
    //   (datas) => {
    //     const decoded = jwt_decode(datas);
    //     dispatch({
    //       type: "SET_CURRENT_USER",
    //       payload: decoded,
    //     });
    //   },
    //   (err) => {}
    // );

    cActions.getConstants();
    loadReCaptcha(recaptcha_token, () => {
      console.log("captcha loaded !");
    });
  }, []);

  return {
    constantsLoaded: Object.keys(state.constants.items).length ? true : false,
  };
};

export default useAuth;
