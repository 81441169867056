import Control from "../../../../common/Controls/Control";
import FileUpload from "../../../../common/FileUpload/FileUpload";
import SaveBtn from "../../../../common/SaveBtn/SaveBtn";
import useStoreState from "../../Context/useStoreState";

const AnnexeForm = ({ newFile, setNewFile, isLoading, saveNewAnnexeFile }) => {
  const { gState } = useStoreState();
  const { FILE_TYPES } = gState.constants.items;

  return (
    <div className="row">
      <div className="col-12">
        <div>
          <strong>Ajouter un fichier annexe</strong>
        </div>
      </div>
      <div className="col-12 col-md-6">
        <Control
          label="Nom du fichier"
          type="text"
          name="name"
          value={newFile.name}
          change={(e) => setNewFile({ ...newFile, name: e.target.value })}
        />
      </div>
      <div className="col-12 col-md-6">
        <Control
          label="Type de fichier"
          type="btnList"
          btnInline
          datas={FILE_TYPES}
          dataIndex="id"
          dataLabel="name"
          name="type"
          value={newFile.type}
          change={(e) => setNewFile({ ...newFile, type: e.target.value })}
        />
      </div>

      {newFile.type == "VIDEO" ? (
        <div className="col-12">
          <Control
            label="Id de la vidéo youtube"
            type="text"
            name="binary"
            value={newFile.binary}
            change={(e) => setNewFile({ ...newFile, binary: e.target.value })}
          />
        </div>
      ) : (
        <>
          <div className="col-12">
            <div>
              <label>
                <span className="label-text">Fichier</span>
              </label>
            </div>
            <FileUpload
              newBinary={newFile.binary}
              clearNewFile={() => setNewFile({ ...newFile, binary: null })}
              onFileDrop={(file) => setNewFile({ ...newFile, binary: file })}
            />
          </div>
        </>
      )}
      <div className="col-12">
        <div className="d-flex justify-content-end w-100">
          <SaveBtn
            save={saveNewAnnexeFile}
            isSaving={isLoading}
            text="Envoyer le fichier"
          />
        </div>
      </div>
    </div>
  );
};

export default AnnexeForm;
