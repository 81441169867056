import ItemStore from "./Context/ItemStore";
import ProjectDetailPage from "./ProjectDetailPage";
import itemReducer from "./Context/reducers/itemsReducer";
import Panels from "./Panels/Panels";
import ViewSwitcher from "./ViewSwitcher/ViewSwitcher";

const ProjectDetail = (props) => {
  return (
    <ItemStore rootReducer={itemReducer}>
      <ProjectDetailPage>
        <Panels />
        <ViewSwitcher />
      </ProjectDetailPage>
    </ItemStore>
  );
};

export default ProjectDetail;
