import React, { useState } from "react";
import useFile from "../../../hooks/useFile/useFile";
import SaveBtn from "../SaveBtn/SaveBtn";
const Download = ({ file }) => {
  const { downloadFile } = useFile();
  const [isDownloading, setIsDownloading] = useState(false);

  return (
    <SaveBtn
      isSaving={isDownloading}
      save={() =>
        downloadFile({
          file,
          onDownloading: setIsDownloading(true),
          onFinished: setIsDownloading(false),
        })
      }
      text="Télécharger"
    />
  );
};

export default Download;
