import useStore from "../../../../context/useStore";

const { default: Control } = require("../../../common/Controls/Control");

const UserManagerDetailForm = ({ userManager, setUserManager, errors }) => {
  const [state, dispatch] = useStore();
  const { MANAGER_ROLES } = state.constants.items;

  return (
    <>
      <Control
        label="Nom"
        type="text"
        name="lastname"
        value={userManager.lastname}
        change={(e) =>
          setUserManager({ ...userManager, lastname: e.target.value })
        }
        error={errors}
      />
      <Control
        label="Prénom"
        type="text"
        name="firstname"
        value={userManager.firstname}
        change={(e) =>
          setUserManager({ ...userManager, firstname: e.target.value })
        }
        error={errors}
      />
      <Control
        label="Email"
        type="email"
        name="email"
        value={userManager.email}
        change={(e) =>
          setUserManager({ ...userManager, email: e.target.value })
        }
        error={errors}
      />
      <Control
        label="Téléphone"
        type="tel"
        name="phone"
        value={userManager.phone}
        change={(e) =>
          setUserManager({ ...userManager, phone: e.target.value })
        }
        error={errors}
      />
      {!userManager.id && (
        <Control
          label="Mot de passe"
          type="password"
          name="password"
          value={userManager.password}
          change={(e) =>
            setUserManager({ ...userManager, password: e.target.value })
          }
          error={errors}
        />
      )}
      <Control
        label="Rôle"
        type="btnList"
        datas={MANAGER_ROLES}
        dataIndex="id"
        dataLabel="name"
        name="role"
        btnInline
        value={userManager.role}
        change={(e) => setUserManager({ ...userManager, role: e.target.value })}
        error={errors}
      />
    </>
  );
};

export default UserManagerDetailForm;
