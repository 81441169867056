import { DateTime } from "luxon";
import CardList from "../../../../common/CardList/CardList";
import Control from "../../../../common/Controls/Control";
import SaveBtn from "../../../../common/SaveBtn/SaveBtn";
import Table from "../../../../common/Table/Table";

const PaymentList = ({
  isLoading,
  payments,
  actions,
  paymentStatuses,
  paymentMethods,
  idsChanged,
}) => {
  return (
    <CardList
      datas={payments}
      fields={[
        {
          name: "Libellé",
          showName: true,
          path: "*",
          render: (el) => (
            <Control
              type="text"
              value={el.name}
              name="name"
              change={(e) => actions.updatePayment({ ...el }, e)}
            />
          ),
        },
        {
          name: "Montant",
          showName: true,
          path: "*",
          render: (el) => (
            <Control
              type="float"
              value={el.amount}
              name="amount"
              change={(e) => actions.updatePayment({ ...el }, e)}
              suffix={"€"}
            />
          ),
        },
        {
          name: "Statut",
          showName: true,
          path: "*",
          render: (el) => (
            <Control
              name="status"
              type="btnList"
              datas={paymentStatuses}
              dataIcon="icon"
              dataIndex="id"
              dataLabel="name"
              value={el.status}
              change={(e) => actions.updatePayment({ ...el }, e)}
            />
          ),
        },
        {
          name: "Date de paiement",
          showName: true,
          path: "*",
          render: (el) => (
            <Control
              type="date"
              name="paidAt"
              value={DateTime.fromISO(el.paidAt).toISO()}
              change={(e) => actions.updatePayment({ ...el }, e)}
            />
          ),
        },
        {
          name: "Méthode",
          showName: true,
          path: "*",
          render: (el) => (
            <Control
              type="select"
              value={el.paymentMethod}
              datas={paymentMethods}
              dataIndex="id"
              dataLabel="name"
              name="paymentMethod"
              change={(e) => actions.updatePayment({ ...el }, e)}
            />
          ),
        },
        {
          name: "Reçu stripe",
          path: "stripeReceipt",
          render: (el) => (
            <a target="_BLANK" href={el}>
              Ticket de reçu
            </a>
          ),
        },
        {
          name: "Actions",
          path: "*",
          render: (el) => (
            <div className="d-flex flex-row">
              {idsChanged.includes(el.id) && (
                <SaveBtn
                  save={() => actions.savePayment(el)}
                  isSaving={isLoading}
                  text={<i className="fa fa-save" />}
                />
              )}
              <button
                className="btn btn-danger"
                onClick={() => actions.deletePayment(el.id)}
              >
                <i className="fa fa-times" />
              </button>
            </div>
          ),
        },
      ]}
    />
  );
};

export default PaymentList;
