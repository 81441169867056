import React, { useState } from "react";
import useComments from "../../../../../hooks/useComments/useComments";
import CardList from "../../../../common/CardList/CardList";
import FileUpload from "../../../../common/FileUpload/FileUpload";
import SaveBtn from "../../../../common/SaveBtn/SaveBtn";
import Table from "../../../../common/Table/Table";
import VideoItem from "../../../../common/VideoItem/VideoItem";

const DocumentList = ({
  documents,
  fileTypes,
  setDocuments,
  deleteDocumentFile,
  isLoading,
  uploadInlineFile,
  updateFileAuthorization,
  fetchDocumentFiles,
}) => {
  const { openComments } = useComments(fetchDocumentFiles);
  const onTempFileDrop = (file, element) => {
    var newDocuments = [...documents];
    newDocuments = newDocuments.map((doc) => {
      if (doc.file.name == element.name) {
        return {
          ...doc,
          fileTemp: { ...doc.fileTemp, binary: file },
        };
      }
      return doc;
    });
    setDocuments(newDocuments);
  };
  const onFileDrop = (file, element) => {
    var newDocuments = [...documents];
    newDocuments = newDocuments.map((doc) => {
      if (doc.fileTemp.name == element.name) {
        return {
          ...doc,
          file: { ...doc.file, binary: file },
        };
      }
      return doc;
    });
    setDocuments(newDocuments);
  };

  const clearNewFileTemp = (element) => {
    var newDocuments = [...documents];
    newDocuments = newDocuments.map((doc) => {
      if (doc.file.name == element.name) {
        return {
          ...doc,
          fileTemp: { ...doc.fileTemp, binary: null },
        };
      }
      return doc;
    });

    setDocuments(newDocuments);
  };
  const clearNewFile = (element) => {
    var newDocuments = [...documents];
    newDocuments = newDocuments.map((doc) => {
      if (doc.fileTemp.name == element.name) {
        return {
          ...doc,
          file: { ...doc.file, binary: null },
        };
      }
      return doc;
    });

    setDocuments(newDocuments);
  };

  const deleteFile = (id) => {
    deleteDocumentFile(id);
  };

  const checkIsVideo = (element) => {
    if (element.file) {
      return element.file.type == "VIDEO";
    }
    if (element.fileTemp) {
      return element.fileTemp.type == "VIDEO";
    }
  };

  console.log("documents", documents);

  return (
    <CardList
      datas={documents}
      fields={[
        {
          name: "Nom",
          path: "*",
          render: (el) => {
            var ft = el.file
              ? fileTypes.find((ft) => ft.id == el.file.type)
              : fileTypes.find((ft) => ft.id == el.fileTemp.type);
            return el.file ? (
              <div className="text-center w-100">
                <div
                  className="text-default font-weight-bold"
                  style={{ fontSize: 22 }}
                >
                  {el.file.name}
                </div>
                <small>
                  <i className={`fa mr-1 fa-${ft.icon}`} />
                  {ft.name}
                </small>
              </div>
            ) : (
              <div className="text-center w-100">
                <div
                  className="text-default font-weight-bold"
                  style={{ fontSize: 22 }}
                >
                  {el.fileTemp.name}
                </div>{" "}
                <small>
                  <i className={`fa mr-1 fa-${ft.icon}`} />
                  {fileTypes.find((ft) => ft.id == el.fileTemp.type).name}
                </small>
              </div>
            );
          },
        },

        {
          name: "Watermark",
          showName: true,
          path: "*",
          headerClassname: "text-center",
          render: (el) => (
            <>
              {checkIsVideo(el) ? (
                <div>-</div>
              ) : (
                <div className="d-center flex-column w-100">
                  <FileUpload
                    noFixedHeight={true}
                    existingFile={el.fileTemp.id ? el.fileTemp : null}
                    newBinary={el.fileTemp.binary}
                    clearNewFile={() => clearNewFileTemp(el.fileTemp)}
                    onFileDrop={(file) => onTempFileDrop(file, el.fileTemp)}
                    deleteFile={() => deleteFile(el.fileTemp.id)}
                    autorizedToView={el.fileTemp.binaryTempAuthorizedToView}
                    updateAuthorized={(authorized) =>
                      updateFileAuthorization(authorized, el.fileTemp)
                    }
                  />

                  {el.fileTemp.binary && (
                    <SaveBtn
                      className="mt-2"
                      save={() => uploadInlineFile(el.fileTemp, true)}
                      isSaving={isLoading}
                    />
                  )}
                </div>
              )}
              {el.fileTemp?.id && (
                <div className="d-center">
                  <button
                    className={`btn  btn-sm position-relative ${
                      el.fileTemp?.comments?.length
                        ? "text-primary"
                        : "text-input"
                    }`}
                    onClick={() => {
                      openComments(el.fileTemp);
                    }}
                  >
                    <i className="fa fa-comment fa-2x" />
                    <span
                      className="position-absolute text-white"
                      style={{
                        left: "50%",
                        top: "45%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      {el.fileTemp?.comments?.length || 0}
                    </span>
                  </button>
                </div>
              )}
            </>
          ),
        },
        {
          name: "Production",
          showName: true,
          path: "*",
          headerClassname: "text-center",

          render: (el) => (
            <>
              {checkIsVideo(el) ? (
                <div className="d-center flex-row w-100">
                  <VideoItem
                    file={el.file}
                    deleteFile={() => deleteFile(el.file.id)}
                  />
                </div>
              ) : (
                <div className="d-center flex-column w-100">
                  <FileUpload
                    noFixedHeight={true}
                    existingFile={el.file.id ? el.file : null}
                    newBinary={el.file.binary}
                    clearNewFile={() => clearNewFile(el.file)}
                    onFileDrop={(file) => onFileDrop(file, el.file)}
                    deleteFile={() => deleteFile(el.file.id)}
                    autorizedToView={el.file.binaryAuthorizedToView}
                    updateAuthorized={(authorized) =>
                      updateFileAuthorization(authorized, el.file)
                    }
                  />

                  {el.file.binary && (
                    <SaveBtn
                      className="mt-2"
                      save={() => uploadInlineFile(el.file, false)}
                      isSaving={isLoading}
                    />
                  )}
                </div>
              )}
              {el.file?.id && (
                <div className="d-center">
                  <button
                    className={`btn  btn-sm position-relative ${
                      el.file?.comments?.length ? "text-primary" : "text-input"
                    }`}
                    onClick={() => {
                      openComments(el.file);
                    }}
                  >
                    <i className="fa fa-comment fa-2x" />
                    <span
                      className="position-absolute text-white"
                      style={{
                        left: "50%",
                        top: "45%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      {el.file?.comments?.length || 0}
                    </span>
                  </button>
                </div>
              )}
            </>
          ),
        },
      ]}
    />
  );
};

export default DocumentList;
