import axios from "axios";
import FileDownload from "js-file-download";
import { API_URL } from "../../config";
import dataService from "../../helpers/dataService";

const useFile = () => {
  const uploadFile = ({
    category,
    binary,
    type,
    entity,
    name,
    isWatermarkVersion = false,
    authorizedToView = true,
    onUploaded = () => {},
    onError = () => {},
    onFinished = () => {},
  }) => {
    let data = new FormData();
    if (type == "VIDEO") {
      data.append("youtubeId", binary);
      data.append("authorizedToView", authorizedToView);
    } else {
      data.append("isWatermarkVersion", isWatermarkVersion);
      data.append("authorizedToView", authorizedToView);
      data.append("file", binary);
    }
    data.append("fileType", type);
    data.append("entity", entity);
    data.append("name", name);

    dataService.postFile(
      `files/${category}`,
      data,

      onUploaded,
      onError,
      onFinished
    );
  };

  const deleteFile = ({
    id,
    onRemoved = () => {},
    onError = () => {},
    onFinished = () => {},
  }) => {
    dataService.remove(`files/${id}`, {}, onRemoved, onError, onFinished);
  };

  const downloadFile = ({
    file,
    onDownloading = () => {},
    onDownloaded = () => {},
    onError = () => {},
    onFinished = () => {},
  }) => {
    onDownloading();
    axios({
      url: `${API_URL}files/${file.id}/download`,
      method: "GET",
      responseType: "blob",
    })
      .then((res) => {
        FileDownload(res.data, `${file.name}${file.fileExt}`);
        onDownloaded();
      })
      .catch((err) => {
        onError(err);
      })
      .then(() => {
        onFinished();
      });
  };

  return {
    uploadFile,
    deleteFile,
    downloadFile,
  };
};

export default useFile;
