import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import dataService from "../../../../helpers/dataService";
import Control from "../../../common/Controls/Control";
import Conversation from "../../../common/FileViewer/Conversation";
import Loader from "../../../common/Loader";
import SaveBtn from "../../../common/SaveBtn/SaveBtn";

const CommentsPanel = ({ myFile, refetch }) => {
  console.log("myFile", myFile);

  const [currentFile, setCurrentFile] = useState(null);

  useEffect(() => {
    setCurrentFile(myFile);
  }, [myFile]);

  const [isSavingComment, setIsSavingComment] = useState(false);

  const sendComment = (file) => {
    setIsSavingComment(true);
    dataService.post(
      `files/${file.id}/comments`,
      {
        content: file.comment,
      },
      (datas) => {
        setCurrentFile(datas);
        refetch();
      },
      (err) => {
        toast.error("Une erreur s'est produite");
      },
      () => setIsSavingComment(false)
    );
  };

  const updateCommentStatus = (fileId, commentId, status) => {
    setIsSavingComment(true);
    dataService.patch(
      `files/${fileId}/comments/${commentId}/status`,
      { status },
      (datas) => {
        setCurrentFile(datas);
        refetch();
      },
      (err) => {
        toast.error("Une erreur s'est produite");
      },
      () => setIsSavingComment(false)
    );
  };

  const handleChange = (e) => {
    setCurrentFile({ ...currentFile, comment: e.target.value });
  };

  return !currentFile ? (
    <Loader />
  ) : (
    <div>
      <Conversation
        comments={currentFile.comments}
        updateCommentStatus={updateCommentStatus}
        fileId={currentFile.id}
        isOpen={true}
        isSavingComment={isSavingComment}
      />
      <Control
        innerLabelStyle={{ color: "white" }}
        label="Laisser un commentaire"
        value={currentFile.comment || ""}
        type="textarea"
        change={handleChange}
        placeholder={"Ecrivez vos remarques"}
        inputGroupClassname="bg-trans pl-0 w-100"
        className="w-100"
        inputStyle={{
          background: "#dedcdc",
          // width: "100%",
          color: "#000000",
          // fontSize: 14,
          // padding: 4,
          height: 105,
          width: 283,
        }}
        innerLabelStyle={
          {
            // color: "#e0e0e0",
            // display: "block",
            // marginBottom: 5,
          }
        }
      />
      <div className="d-flex justify-content-end w-100">
        <SaveBtn
          save={() => sendComment(currentFile)}
          isSaving={isSavingComment}
          text={
            <>
              Envoyer <i className="fa fa-paper-plane ml-2" />
            </>
          }
        />
      </div>
    </div>
  );
};

export default CommentsPanel;
