import { Link } from "react-router-dom";
import Page from "../../../common/layout/Page";
import Pagination from "../../../common/Pagination/Pagination";
import UserManagersFilters from "./UserManagersFilters";
import UserManagersListTable from "./UserManagersListTable";
import useUserManagersList from "./useUserManagersList";

const UserManagers = () => {
  const {
    isLoading,
    userManagers,
    search,
    setSearch,
    actions,
    errors,
    nbPages,
    nbResults,
  } = useUserManagersList();

  return (
    <Page
      title="Utilisateurs du backoffice"
      back="/"
      errors={errors}
      isLoading={isLoading}
    >
      <Link
        className="btn btn-primary d-inline-flex align-items-center"
        to="user-managers/create"
      >
        <i className="fa fa-plus mr-2" />
        Créer un nouvel utilisateur
      </Link>
      <div className="">
        <UserManagersFilters
          search={search}
          setSearch={setSearch}
          searchUserManagers={actions.search}
        />
        <UserManagersListTable
          isLoading={isLoading}
          userManagers={userManagers}
          nbResults={nbResults}
          removeUserManager={actions.remove}
        />
        <Pagination
          nbPages={nbPages}
          page={search.page}
          changePage={(p) => setSearch({ ...search, page: p })}
        />
      </div>
    </Page>
  );
};

export default UserManagers;
