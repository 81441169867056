import { useState } from "react";
import Control from "../../../../common/Controls/Control";
import SaveBtn from "../../../../common/SaveBtn/SaveBtn";
import useStoreState from "../../Context/useStoreState";
import ClientInfos from "./ClientInfos";

const ClientForm = () => {
  const { state, items, items_actions } = useStoreState();
  const { newClient, errors, project } = items;

  const handleChange = (e) => {
    var newClient = {
      ...newClient,
      [e.target.name]: e.target.value,
    };

    items_actions.updateNewClient(newClient);
  };

  return (
    <div className="custom-card vivify pullRight">
      <div>
        <strong>Création d'un nouveau client</strong>
      </div>
      <Control
        label="Nom"
        name="lastname"
        type="text"
        value={newClient.lastname}
        change={handleChange}
        error={errors}
      />
      <Control
        label="Prénom"
        name="firstname"
        type="text"
        value={newClient.firstname}
        change={handleChange}
        error={errors}
      />
      <Control
        label="Email"
        name="email"
        type="email"
        value={newClient.email}
        change={handleChange}
        error={errors}
      />
      <Control
        label="Téléphone"
        name="phone"
        type="tel"
        value={newClient.phone}
        change={handleChange}
        error={errors}
      />
      <Control
        label="Mot de passe"
        name="password"
        type="password"
        value={newClient.password}
        change={handleChange}
        error={errors}
      />
      <Control
        label="Confirmez le mot de passe"
        name="passwordConfirm"
        type="password"
        value={newClient.passwordConfirm}
        change={handleChange}
        error={
          newClient.password != newClient.passwordConfirm &&
          newClient.password.length
            ? {
                passwordConfirm: "N'est pas identique à celui du dessus",
              }
            : {}
        }
      />
      <div className="d-flex justify-content-end">
        <SaveBtn
          save={items_actions.saveNewClient}
          typeBtn="submit"
          isSaving={state.isLoadingNewClient}
          text="Créer le client"
        />
      </div>
    </div>
  );
};

export default ClientForm;
